import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import { SET_OFFERS } from '../redux/reducers/offer';
import Offers from '../offers';
import OffersV2 from '../offersV2';
import RegistrationBlock from '../registration';
import { getBaseUrl } from '../config/retailerConfig';
import { shouldRenderComponent } from '../shared/util/ComponentAuth';


function OffersBlock({ updateLoadingState, page }) {
  const siteConfig = page;
  const offerBlock = siteConfig.components.find((component) => component.block_type === 'OfferBlock');
  const dispatch = useDispatch();

  const {
    config,
    config: {
      urlParam: { ids: offerIds },
    },
  } = useSelector((state) => state);
  const retailer = page.dpn_instance ? page.dpn_instance : config.retailerConfig.dpn_instance;

  const hostname = window.location.hostname;
  const isLocalhost = hostname.includes('localhost');
  const devShowAllOffers = false;

  const [isLoading, setIsLoading] = useState(true);
  const storedClips = JSON.parse(localStorage.getItem('pbyt')) || { myIds: [] };

  const useStyles = makeStyles((theme) => ({
    offerContainer: {
      paddingBottom: "80px",
      paddingTop: "80px",
      [theme.breakpoints.down(1025)]: {
        paddingBottom: "40px",
        paddingTop: "40px",  
      },
    }
  }));
  const classes = useStyles();

  useEffect(() => {
    fetchOffers();
  }, []); // Only fetch offers once on component mount

  if (!shouldRenderComponent(offerBlock.auth_visibility)) {
    return null;
  }

  function parseOffers(data) {
    const parsed = {
      clipped: [],
      available: [],
      featured: [],
    };

    data.forEach((offer) => {
      const display_start_date = new Date(offer.clipStartDate.iso);
      const display_end_date = new Date(offer.clipEndDate.iso);
      offer.display_start_date = display_start_date;
      offer.display_end_date = display_end_date;

      if (storedClips.myIds.includes(offer.mdid)) {
        offer.clipped = true;
      }
      if (Object.values(offerBlock.feature_set).find(e => e.offer_id === offer.mdid)) {
        parsed.featured.push(offer);
      } else if (config.urlParam.fid || config.urlParam.FID) {
        const fidArray = config.urlParam.fid || config.urlParam.FID || [];
        if (fidArray.includes(offer.mdid)) {
          parsed.featured.push(offer);
        } else {
          parsed.available.push(offer);
        }
      } else {
        parsed.available.push(offer);
      }

    });

    dispatch(SET_OFFERS({ collections: parsed }));
    setIsLoading(false);
  }

  function fetchOffers() {
    let url = '/offers';
    let limit = offerBlock.use_test_offers ? 30 : 99;
    if (config.urlParam.testing) {
      url = '/json/coupons.json';
    }

    let urlObject = {};
    if (offerIds && !config.urlParam.fid && !config.urlParam.FID) {
      urlObject.ids = offerIds;
    } else if (offerBlock.mp_id) {
      urlObject.mediaPropertyId = offerBlock.mp_id;
    }
    
    if (offerBlock.tag) {
      urlObject.tags = offerBlock.tag;
    }

    if (offerBlock.use_test_offers) {
      urlObject.mediaPropertyId = '';
    }
    url += '?' + queryString.stringify({ ...urlObject, limit });

    fetch(getBaseUrl(retailer.dpn_instance, true) + url, {
      headers: {
        'Content-Type': 'application/json',
        'x-retailer': retailer.dpn_instance,
        Origin: window.location.origin,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          parseOffers(data);
        } else {
          setIsLoading(false); // No offers found
        }
      })
      .catch((error) => {
        console.error('Error fetching offers:', error);
        setIsLoading(false); // Handle errors by setting isLoading to false
      });
  }

  const offers = {
    clipped: [],
    available: [],
    featured: [],
  };

  // Populate 'offers' object based on fetched data (this is for rendering the component)

  return (
    <div className={classes.offerContainer}>
      <OffersV2 offers={offers} page={page} retailer={retailer}/> 
    </div>
  );
}

export default OffersBlock;
