export function convertDate(dateString) {
    // Create a Date object from the string
    const date = new Date(dateString);
    
    // Check if the date is valid
    if (isNaN(date.getTime())) {
        return 'Invalid date';
    }
    
    // Extract day, month, and year
    const day = String(date.getUTCDate()).padStart(2, '0'); // Use getUTCDate() to avoid DST issues
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getUTCFullYear(); // Use getUTCFullYear() for the full year

    // Format the date as DD/MM/YYYY
    const formattedDate = `${month}/${day}/${year}`;
    
    return formattedDate;
}

export function getAmountButtons(balance, minTranAmount) {
  let updatedAmountButtons = [];

  if (balance <= minTranAmount * 2) {
    return { buttons: [balance, minTranAmount, minTranAmount * 2, minTranAmount *3] };
  }

  let amount = minTranAmount;
  updatedAmountButtons.push(balance);

  while(balance > amount &&  updatedAmountButtons.length < 8) {
    updatedAmountButtons.push(amount);
    amount = updatedAmountButtons[updatedAmountButtons.length -1] +  Math.ceil((Number(balance/8)/10)) * 10;
  }

  return { buttons: updatedAmountButtons };
}

