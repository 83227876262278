import React, { useState, useEffect } from 'react';
import './Rebates.scss';
import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getOfferSummaryByOfferCode } from '../service/submission_service';

const useStyles = makeStyles((theme) => ({
    spinnerContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '20vh',
    },
  }));

function FeaturedOfferDisplay({ page, onOpenModal, featuredOfferData }) {
  const classes = useStyles();
  const [offerSummary, setOfferSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [mergedOfferData, setMergedOfferData] = useState(null);
  
  const rebatesData = page.components.find((component) => component.block_type === 'Rebates');
  const featuredOffer = rebatesData.featured_offers[0];
  let headerTextColor = rebatesData.header_text_color;

  useEffect(() => {
    const fetchOfferSummary = async () => {
      try {
        const summary = await getOfferSummaryByOfferCode(rebatesData.client_number, featuredOffer.offer_code);
        setOfferSummary(summary);

        if (featuredOfferData && summary) {
          setMergedOfferData({
            ...featuredOfferData,
            terms: summary.terms || featuredOfferData.terms || '',
          });
        }

        setLoading(false);
      } catch (err) {
        console.error("Error fetching offer summary:", err);
        setError("Failed to load offer details");
        setLoading(false);
      }
    };

    fetchOfferSummary();
  }, [rebatesData.client_number, featuredOffer.offer_code, featuredOfferData]);

  if (loading) {
    return (
      <Box className={classes.spinnerContainer}>
        <CircularProgress />
      </Box>
    );
  }
  
  if (error) return <div style={{ color: headerTextColor }}>{error}</div>;
  if (!offerSummary || !mergedOfferData) return null;

  const expirationDate = new Date(mergedOfferData.offerEndDate);
  const formattedDate = expirationDate.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  });

  return (
    <>
      <div className='claim-your-rebate-header' style={{ backgroundColor: rebatesData.featured_offer_background, color: rebatesData.primary_text_color }}>
        <h1 className='rebates-h1 claim-your-cash-back' style={{ margin: '0px', marginTop: '15px' }}>
          {window.innerWidth <= 767 ? 'CLAIM YOUR\nCASH BACK' : 'CLAIM YOUR CASH BACK'}
        </h1>
      </div>
      <div className='submission-form-container' style={{ backgroundColor: rebatesData.featured_offer_background }}>
        <div className='image-column-container'>
          <div className='left-column-image-container'>
            <img src={featuredOffer.image} alt={offerSummary.title} />
          </div>
        </div>
        <div className='right-column-image-text'>
          <div className='save-header' style={{ color: rebatesData.primary_text_color }}>
            {mergedOfferData.offerTitle}
          </div>
          <div className='save-subheader' style={{ color: rebatesData.primary_text_color }}>
            {mergedOfferData.offerDescription}
          </div>
          <div className='expiration-date' style={{ color: rebatesData.expiration_text_color }}>
            Expires: {formattedDate}
          </div>
          <div id="submit-receipt-container">
            <button id="submit-receipt" onClick={() => onOpenModal(mergedOfferData)} style={{ backgroundColor: rebatesData.button_background_color ,color: rebatesData.button_text_color }}>
              SUBMIT RECEIPT
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeaturedOfferDisplay;