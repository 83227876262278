import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, makeStyles, Typography, Paper } from "@material-ui/core";
import HeaderNavigation from "../../headerNavigation";
import ProfileWrapper from "./ProfileWrapper";
import PopUpModal from "../../../shared/util/components/dialogComponent";
import { CLEAR_USER } from "../../../redux/reducers/user";
import { deleteUser } from "../../../service/account_service";

export function Profile(props) {
  const { retailer, isMobile, showBackArrow } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openPopUpModal, setOpenPopUpModal] = useState(false);
  const [editMode, setEdit] = useState(false);
  const signupConfig = props.retailer.components.find(
    (component) => component.block_type === "SignupBlock"
  );
  const header = props.retailer.components.find(
    (component) => component.block_type === "HeaderNavigationBlock"
  );
  const colorTheme = props.retailer.brand_design_theme;
  const cardBackgroundColor = colorTheme?.background_color
    ? colorTheme?.background_color
    : "transparent";
  const dpnInstance = props?.retailer?.dpn_instance?.dpn_instance;
  const formFields = signupConfig?.retailerFields?.formFields;
  const retailerDpnInstance =
    props?.retailer.retailer_dpn_instance?.dpn_instance;
  const formatRetailerDpnInstance =
    retailerDpnInstance.charAt(0).toUpperCase() + retailerDpnInstance.slice(1);
  const retailerWebsite = props?.retailer.retailer_dpn_instance?.website_link;
  const cachedUser = JSON.parse(localStorage.getItem("userData"));
  const siteType = props?.retailer.site_type;

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      overflow: "hidden",
      [theme.breakpoints.down("xs")]: {
        "& .tabPanelSection": {
          display: "none",
        },

        "& .MuiTypography-h2": {
          fontSize: "3.0rem",
        },
      },
    },
    accountInfo: {
      marginLeft: isMobile ? 0 : "8px",
      textAlign: "left",
    },
    accountID: {
      fontWeight: 700,
      fontFamily: "Switzer",
      fontSize: "18px",
      lineHeight: "27px",
      color: colorTheme?.text_color,
    },
    accountPhone: {
      fontWeight: 400,
      fontFamily: "Switzer",
      fontSize: "18px",
      lineHeight: "27px",
      color: colorTheme?.text_color,
    },
    accountDescription: {
      fontWeight: 400,
      fontFamily: "Switzer",
      fontSize: "18px",
      lineHeight: "27px",
      color: colorTheme?.text_description_color,
      // Mobile css
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    accountWebLink: {
      color: colorTheme?.link_color,
      "&:hover": {
        color: colorTheme?.link_color,
        textDecoration: "underline",
      },
    },
    content: {
      backgroundColor: cardBackgroundColor,
      padding: isMobile ? "0 10px" : "0 40px",
      outline: "none",
      textAlign: "center",
      maxWidth: "708px",
    },
    header: {
      display: "grid",
      gridTemplateColumns: "auto auto",
      padding: "10px",
      paddingLeft: isMobile ? 0 : "10px",
      "& .pageTitle": {
        paddingBottom: "30px",
        fontFamily: "Switzer",
        fontWeight: 700,
        fontSize: isMobile ? "30px" : "42px",
        lineHeight: "46.2px",
        color: colorTheme?.text_color,
      },
    },
    profileBody: {
      margin: "20px 0",
      backgroundColor: "#EEF0F2",
      borderRadius: "10px",

      "& .MuiTypography-h4": {
        padding: "20px 0",
      },

      "& .MuiTypography-h5": {
        fontWeight: 300,
        padding: "20px 0",
      },

      "& .MuiButton-root": {
        fontSize: "14px !important",
        color: "#fff !important",
      },
    },
    profileBodyMob: {
      "& .pageTitle": {
        marginTop: "10px",
        fontSize: "26px",
        marginLeft: "5px",
        fontFamily: "Switzer",
        fontWeight: 700,
        lineHeight: "66px",
      },
    },
    paper: {
      maxWidth: "100%",
      boxShadow: "none",
      backgroundColor: cardBackgroundColor,
    },
    paperMobile: {
      backgroundColor: cardBackgroundColor,
      maxWidth: 560,
      boxShadow: "none",
    },
    headerItem: {
      fontSize: "18px",
      textAlign: "right",
      marginTop: isMobile ? "15px" : "12px",
      fontWeight: 700,
      fontFamily: "Switzer",
      lineHeight: "19.8px",
    },
    clickableText: {
      cursor: "pointer",
    },
    deleteInfo: {
      display: "grid",
      gridTemplateColumns: "auto auto",
      marginLeft: "5px",
    },
    delteItem1: {
      fontSize: "18px",
      textAlign: "left",
      marginTop: "12px",
      fontWeight: 700,
      fontFamily: "Switzer",
      lineHeight: "19.8px",
    },
    delteItem2: {
      textAlign: "right",
      marginTop: "12px",
      fontSize: "18px",
      fontWeight: 700,
      fontFamily: "Switzer",
      lineHeight: "19.8px",
      color: "#DC3545",
    },
    manageHeader: {
      "& .pageTitle": {
        marginLeft: "5px",
        fontFamily: "Switzer",
        fontWeight: 700,
        fontSize: isMobile ? "30px" : "42px",
        lineHeight: "46.2px",
        color: colorTheme?.text_color,
        padding: isMobile ? "0 0 18px 0" : "40px 0 18px 0",
      },
    },
    deleteDescription: {
      fontWeight: 400,
      fontFamily: "Switzer",
      fontSize: "18px",
      lineHeight: "27px",
      color: colorTheme?.text_description_color,
      marginLeft: "5px",
      textAlign: "left",
      marginBottom: isMobile ? "3rem" : "4rem",
    },
  }));
  const classes = useStyles();

  const handleClick = () => {
    setEdit(true);
  };
  const handleDelete = () => {
    setOpenPopUpModal(true);
  };
  const deleteUserInfo = async () => {
    if (dpnInstance) {
      const response = await deleteUser(cachedUser?.email, dpnInstance);
      if (response.message === "Success") {
        dispatch(CLEAR_USER());
        localStorage.clear();
        navigate(`/${retailer?.brandsite_name}/signup`);
      }
    }
    setOpenPopUpModal(false);
  };
  return (
    <Paper className={isMobile ? classes.paperMobile : classes.paper}>
      <Box
        style={{
          width: "100%",
          height: isMobile.isMobile ? "100vh" : "100%",
          background: "#fff",
          backgroundColor: cardBackgroundColor,
        }}
      >
        {isMobile && header && showBackArrow && (
          <HeaderNavigation page={props.retailer} />
        )}
        <div className={classes.content}>
          <div className={classes.header}>
            <Typography
              className="pageTitle headerItem"
              align="left"
              variant="h3"
              component="h3"
              noWrap
            >
              Profile details
            </Typography>
            <div className={classes.headerItem}>
              <span className={classes.clickableText} onClick={handleClick}>
                Edit
              </span>
            </div>
          </div>
          <div className={isMobile && classes.profileBodyMob}>
            {siteType !== "MyLoyalty" && (
              <div className={classes.accountInfo}>
                <p className={classes.accountID}>
                  {formatRetailerDpnInstance} Member ID
                </p>
                <p className={classes.accountPhone}>{cachedUser?.phone}</p>
                <p className={classes.accountDescription}>
                  Your {formatRetailerDpnInstance} phone number can be changed
                  on the{" "}
                  <a href={retailerWebsite} className={classes.accountWebLink}>
                    {formatRetailerDpnInstance} website
                  </a>
                  . Once you change your phone number on the{" "}
                  {formatRetailerDpnInstance} website, your new phone number
                  will be linked to this program.
                </p>
              </div>
            )}
            <ProfileWrapper
              formType="profile"
              config={signupConfig}
              fields={formFields}
              retailerName={dpnInstance}
              editField={editMode}
              setEditField={setEdit}
              siteConfig={props.retailer}
              setCancel={() => setEdit(false)}
            />
          </div>
          <>
            <div className={classes.manageHeader}>
              <Typography
                className="pageTitle headerItem"
                align="left"
                variant="h3"
                component="h3"
                noWrap
              >
                Manage
              </Typography>
            </div>

            <div className={classes.deleteInfo}>
              <p className={classes.delteItem1}>Delete account</p>
              <div className={classes.delteItem2}>
                <span className={classes.clickableText} onClick={handleDelete}>
                  Delete
                </span>
              </div>
            </div>
            <p className={classes.deleteDescription}>
              {signupConfig?.profile_delete_text}
            </p>
          </>
        </div>
      </Box>
      {openPopUpModal && (
        <PopUpModal
          open={openPopUpModal}
          popUpText={signupConfig?.profile_delete_popup_text}
          popUpTitle={"Are you sure you want to delete your account?"}
          colourButtonName="Delete"
          whiteButtonName="Cancel"
          mainColor={
            signupConfig.primary_color || colorTheme?.button_background_color
          }
          showClose={true}
          closeModal={() => setOpenPopUpModal(false)}
          colourButtonAction={deleteUserInfo}
          whiteButtonAction={() => setOpenPopUpModal(false)}
        />
      )}
    </Paper>
  );
}

export default Profile;
