import React, { useEffect, useState } from "react";
import {
  Grid,
  makeStyles,
  useMediaQuery,
  createTheme,
  Button,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import PaymentModal from "./paymentModal";
import { getUserPoints } from "../../service/account_service";
import { getProgramDetails } from "../../service/myloyalty_services";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import PayPalImg from "../../assets/images/payPal.png";
import { getAmountButtons } from "../../shared/util/utils";

export function CashoutBlock(props) {
  const blockConfig = props.page.components.find(
    (component) => component.block_type === "CashoutBlock"
  );
  const siteConfig = useSelector((state) => state.config.retailerConfig);

  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [payPalBalance, setPayPalBalance] = useState();
  const [openPaymentModal, setOpenPaymentModal] = useState();
  const [minTransferAmt, setMinTransferAmt] = useState(200);
  const [selAmount, setSelAmount] = useState(null);
  const [error, setError] = useState(null);

  const useStyles = makeStyles((theme) => ({
    rootCtn: {
      maxWidth: "1200px",
      margin: "0 auto",
      padding: "20px",

      [theme.breakpoints.down("xs")]: {
        padding: "0",
      },
    },
    leftSection: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "10% 0",

      "& .title": {
        fontSize: "90px",
        fontFamily: "switzer",
        fontWeight: "600",
        margin: "12px 0",
        letterSpacing: "1px",
        lineHeight: "81px",
      },

      "& .desc": {
        width: "100%",
        fontSize: "18px",
        lineHeight: "27px",
        fontFamily: "switzer",
        fontWeight: "400",
        marginTop: "24px",
      },

      // Mobile
      [theme.breakpoints.down("xs")]: {
        margin: "12px 0",

        "& .title": {
          fontSize: "54px",
          lineHeight: "50px",
        },

        "& .desc": {
          fontSize: "16px",
          marginTop: "0",
        },
      },
    },
    rightSection: {
      boxShadow:
        "0px 5px 10px 0px rgba(107, 114, 128, 0.05), 0px 15px 25px 0px rgba(107, 114, 128, 0.15)",
      borderRadius: "24px",
      border: "1px solid #B6BAC3",
      padding: "24px",

      "& .header": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "32px",
      },

      "& .subTitle": {
        fontSize: "42px",
        fontFamily: "switzer",
        fontWeight: "600",
      },

      "& .summarySection": {
        display: "flex",
        justifyContent: "space-between",
        marginBlock: "32px",
      },

      "& .Mui-disabled": {
        background: "#EDEEF1 !important",
        color: "#6d7b88 !important",
      },

      [theme.breakpoints.down("xs")]: {
        padding: "12px",

        "& .subTitle": {
          fontSize: "24px",
        },

        "& .summarySection": {
          flexDirection: "column",
        },
      },
    },
    btnListSection: {
      display: "grid",
      gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
      gap: "10px",

      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        gap: "8px",
      },
    },
    amountBtn: {
      display: "flex",
      justifyContent: "center",
      cursor: "pointer",
      fontSize: "28px",
      fontWeight: "700",
      lineHeight: "30.8px",
      whiteSpace: "nowrap",
      fontFamily: "switzer",
      padding: "24px",
      border: "1px solid #B6BAC3",
      background: "#EDEEF1",
      borderRadius: "24px",
      boxShadow:
        "0px 2px 4px 0px rgba(107, 114, 128, 0.12), 0px 3px 5px 0px rgba(107, 114, 128, 0.15)",

      [theme.breakpoints.down("xs")]: {
        fontSize: "18px",
        padding: "16px",
      },
    },
    cashOutBtn: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "27px",
      boxShadow:
        "0px 2px 4px 0px rgba(107, 114, 128, 0.12), 0px 3px 5px 0px rgba(107, 114, 128, 0.15)",
      padding: "16px",
      borderRadius: "240px",
      marginTop: "32px",
      textTransform: "capitalize",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    // getLoyaltyProgramDetails();
    getCashBackUserPoints();
  }, []);

  const getCashBackUserPoints = async () => {
    const response = await getUserPoints(siteConfig.dpn_instance);
    if (!response.error) {
      if (response) {
        const paypal = response.reduce(
          (acc, item) => {
            if (
              item.redemptionType == "external" ||
              item.redemptionType == "unrestricted"
            ) {
              acc.balance += item.balance;
              acc.offers.push(...item.offers);
            }
            return acc;
          },
          { offers: [], balance: 0 }
        );

        setMinTransferAmt(2000);
        paypal.buttonsList = getAmountButtons(
          paypal.balance,
          1000
        );
        setPayPalBalance(paypal);
      }
    } else {
      setError("Error");
    }
  };

  // TODO: Fetch minRedemptionValue from API(https://inmardigital.atlassian.net/browse/ICE-560)
  const getLoyaltyProgramDetails = async () => {
    const response = await getProgramDetails(
      siteConfig.dpn_instance.dpn_instance,
      siteConfig.dpn_instance.loyalty_program_id,
      localStorage.getItem("token")
    );
    if (!response.error) {
      if (response) {
        // getCashBackUserPoints(response);
      }
    } else {
      setError("Error");
    }
  };

  const closeModal = () => {
    setOpenPaymentModal(false);
    setSelAmount(null);
  };

  const onPaymentSuccessHandler = () => {
    setOpenPaymentModal(false);
    setSelAmount(null);
    getCashBackUserPoints();
  };

  const onChangeAmountBtnHandler = (amount) => {
    if (payPalBalance.balance !== 0 && payPalBalance.balance >= amount * 100) {
      setSelAmount(amount * 100);
    }
  };

  const getAmountBtnBgColor = (btn) => {
    if (payPalBalance.balance <= 0 || payPalBalance.balance < btn) {
      return "#EDEEF1";
    }

    return selAmount ===
      (payPalBalance?.balance === btn ? btn / 100 : Math.round(btn / 100)) * 100
      ? blockConfig?.blockstyle_set[0]
        ? blockConfig?.blockstyle_set[0]?.tertiary_color
        : "#f3e8fc"
      : "#fff";
  };

  return (
    <div className={classes.rootCtn}>
      <Grid container spacing={2} style={{ padding: "20px" }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div className={classes.leftSection}>
            <h1 id={blockConfig.anchor_id} className="title">{blockConfig.title}</h1>
            <p className="desc">{blockConfig.title_description}</p>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div className={classes.rightSection}>
            <div className="header">
              <div className="subTitle">Summary</div>
              <div>
                <img src={PayPalImg} style={{ width: "100px" }} />
              </div>
            </div>

            <div className="summarySection" style={{}}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    fontSize: isMobile ? "16px" : "18px",
                    lineHeight: "27px",
                    fontWeight: "700",
                  }}
                >
                  Cashback wallet
                </div>
                <span
                  style={{
                    fontSize: isMobile ? "16px" : "18px",
                    lineHeight: "27px",
                    fontWeight: "400",
                  }}
                >
                  ${(payPalBalance?.balance / 100).toFixed(2)}
                </span>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    fontSize: isMobile ? "16px" : "18px",
                    lineHeight: "27px",
                    fontWeight: "700",
                  }}
                >
                  Redemption method
                </div>
                <span
                  style={{
                    fontSize: isMobile ? "16px" : "18px",
                    lineHeight: "27px",
                    fontWeight: "400",
                  }}
                >
                  Paypal
                </span>
              </div>
            </div>

            <div>
              <label
                style={{
                  fontSize: "18px",
                  lineHeight: "27px",
                  fontWeight: "700",
                }}
              >
                Select amount
              </label>

              <div className={classes.btnListSection}>
                {payPalBalance?.buttonsList.buttons.map((btn, idx) => (
                  <div
                    variant="contained"
                    onClick={() =>
                      onChangeAmountBtnHandler(
                        payPalBalance?.balance === btn
                          ? btn / 100
                          : Math.round(btn / 100)
                      )
                    }
                    style={{
                      background: getAmountBtnBgColor(btn),
                      cursor:
                        payPalBalance.balance > 0 &&
                        payPalBalance.balance >= btn
                          ? "pointer"
                          : "not-allowed ",
                      border:
                        selAmount ===
                        (payPalBalance?.balance === btn
                          ? btn / 100
                          : Math.round(btn / 100)) *
                          100
                          ? `1px solid ${
                              blockConfig?.blockstyle_set[0]?.primary_color
                                ? blockConfig?.blockstyle_set[0]?.primary_color
                                : "#53128C"
                            }`
                          : "1px solid #B6BAC3",
                    }}
                    className={classes.amountBtn}
                  >
                    {payPalBalance?.balance === btn && idx === 0
                      ? "All"
                      : `$ ${Math.round(btn / 100)}`}{" "}
                  </div>
                ))}
              </div>

              <Button
                className={classes.cashOutBtn}
                style={{
                  background: blockConfig?.blockstyle_set[0]?.button_background_color
                    ? blockConfig?.blockstyle_set[0]?.button_background_color
                    : "#53128C",
                  color: blockConfig?.blockstyle_set[0]?.button_text_color
                    ? blockConfig?.blockstyle_set[0]?.button_text_color
                    : "#fff",
                }}
                variant="contained"
                disabled={!selAmount}
                onClick={() => setOpenPaymentModal(true)}
              >
                Cash out
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>

      <Modal
        open={openPaymentModal}
        onClose={() => closeModal()}
        showCloseIcon={true}
        closeOnOverlayClick={true}
        styles={{ borderRadius: "20px" }}
        classNames={{
          modal: "payPalModal",
        }}
      >
        <PaymentModal
          retailer={siteConfig.dpn_instance.dpn_instance}
          payPalImg={PayPalImg}
          transferAmount={selAmount}
          paymentInfo={payPalBalance}
          blockTheme={blockConfig?.blockstyle_set[0]}
          onPaymentSuccess={() => onPaymentSuccessHandler()}
          onClose={() => closeModal()}
        />
      </Modal>
    </div>
  );
}

export default CashoutBlock;
