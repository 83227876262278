import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography, CircularProgress } from '@material-ui/core';
import { getOffers } from '../service/submission_service';
import OfferCard from './OfferCard';
import SubmissionForm from './submissionForm';
import SuccessPopUp from './SuccessPopUp';
import FailedPopUp from './FailedPopUp';
import FeaturedOfferDisplay from './featuredOfferDisplay';
import { trackCashbackOfferLoad, trackCashbackOfferClick } from '../utils/gaTrackingFunctions';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    margin: '0 auto',
    gap: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  gridItem: {
    marginBottom: theme.spacing(4),
  },
  headerText: {
    fontFamily: 'Bebas Neue',
    fontSize: '49px',
    lineHeight: '100%',
    textAlign: 'center',
  },
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
  },
}));

const GridContainer = (page) => {
  const classes = useStyles();
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
  const [formData, setFormData] = useState({});
  const [showFailedPopUp, setShowFailedPopUp] = useState(false);
  const [featuredOfferData, setFeaturedOfferData] = useState(null);

  const deepLinkOfferCode = new URLSearchParams(window.location.search).get('offercode');

  const block = page.page.components.find(component => component.block_type === 'Rebates');
  const featuredOffer = block.featured_offers && block.featured_offers[0];
  const surfaceOnlyOffers = block.surface_only_offers && block.surface_only_offers.split(',');
  let headerTextColor = block.header_text_color;

  useEffect(() => {
    async function fetchOffers() {
      try {
        let offersData;
        
        // list of all offers available for the client
        const clientOffersData = await getOffers(block.client_number);
       
        // offers to be shown only when surface_only_offers is filled
        let showOnlyOffersData = surfaceOnlyOffers ? clientOffersData.filter(offer => surfaceOnlyOffers.includes(offer.offerCode)) : null;    
        offersData = showOnlyOffersData ? showOnlyOffersData : clientOffersData
        const currentDate = new Date();

        if (featuredOffer) {
          //  picking the featured offer specified from all the available offers
          const featuredOfferData = offersData.find(offer => 
            offer.offerCode === featuredOffer.offer_code &&
            new Date(offer.offerStartDate) <= currentDate &&
            new Date(offer.offerEndDate) > currentDate
          );
          if (featuredOfferData) {
            setFeaturedOfferData(featuredOfferData);
            trackCashbackOfferLoad(featuredOfferData.offerCode);
          }
        }

        // all the other client offers except the featured offer to display as cash back offers
        let filteredOffers = offersData.filter(offer => {
          const isNotFeaturedOffer = featuredOffer ? offer.offerCode !== featuredOffer.offer_code : true;
          const startDate = new Date(offer.offerStartDate);
          const endDate = new Date(offer.offerEndDate);
          return isNotFeaturedOffer && startDate <= currentDate && endDate > currentDate;
        });

        if (deepLinkOfferCode) {
          filteredOffers = filteredOffers.filter(offer => offer.offerCode === deepLinkOfferCode);
        }
        

        filteredOffers.sort((a, b) => new Date(a.offerEndDate) - new Date(b.offerEndDate));

        filteredOffers.forEach(offer => {
          trackCashbackOfferLoad(offer.offerCode);
        });

        setOffers(filteredOffers);
      } catch (error) {
        console.error('Error fetching offers:', error);
      } finally {
        setLoading(false);
      }
    }
    fetchOffers();
  }, []);

  const handleOpenModal = (offer) => {
    setSelectedOffer(offer);
    trackCashbackOfferClick(offer.offerCode);
  };

  const handleCloseModal = () => {
    setSelectedOffer(null);
  };

  const handleSuccess = (data) => {
    setFormData(data);
    setShowSuccessPopUp(true);
  };

  const handleSuccessClose = () => {
    setShowSuccessPopUp(false);
  };

  return (
    <>
      <Box sx={{backgroundColor: block.color_background_2}}>
        {featuredOffer && !deepLinkOfferCode && (
          <FeaturedOfferDisplay 
            page={page.page} 
            onOpenModal={handleOpenModal}
            featuredOfferData={featuredOfferData}
          />
        )}
        <Typography variant="h2" component="h2" className={classes.headerText} style={{ color: headerTextColor }}>
          CASH BACK OFFERS
        </Typography>
        {loading ? (
          <Box className={classes.spinnerContainer}>
            <CircularProgress />
          </Box>
        ) : offers.length > 0 ? (
          <Box className={classes.gridContainer}>
            <Grid container spacing={1} justifyContent="center">
              {offers.map((offer, index) => (
                <Grid item className={classes.gridItem} key={index}>
                  <OfferCard offer={offer} clientNumber={block.client_number} onSubmitReceiptClick={() => handleOpenModal(offer)} />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <Typography variant="h6" component="h6" align="center">
            {deepLinkOfferCode ? 'No matching offer found.' : 'No active offers available at this time.'}
          </Typography>
        )}
        {selectedOffer && (
          <SubmissionForm
            open={Boolean(selectedOffer)}
            offer={selectedOffer}
            onClose={handleCloseModal}
            page={page.page}
            setShowFailedPopUp={setShowFailedPopUp}
            onSuccess={handleSuccess}
          />
        )}
        {showSuccessPopUp && (
          <SuccessPopUp
            setShowSuccessPopUp={setShowSuccessPopUp}
            firstName={formData.firstName}
            lastName={formData.lastName}
            email={formData.email}
            paymentMethod={formData.paymentMethod}
            paymentEmail={formData.paymentEmail}
            submissionNumber={formData.submissionNumber}
            venmoNumber={formData.venmoNumber}
            handleSuccess={handleSuccessClose}
            clearForm={() => setFormData({})}
          />
        )}
        {showFailedPopUp && (
          <FailedPopUp
            setShowFailedPopUp={setShowFailedPopUp}
          />
        )}
      </Box>
    </>
  );
};

export default GridContainer;