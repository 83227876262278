import React, { useEffect, useState } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import Avatar from "@mui/material/Avatar";
import { useSelector } from "react-redux";
import { getProgramDetails } from "../service/myloyalty_services";
import { ReactComponent as GiftIcon } from "../assets/images/svg/gift.svg";
import { ReactComponent as BadgeCheckIcon } from "../assets/images/svg/badgeCheck.svg";
import { ReactComponent as DollarIcon } from "../assets/images/svg/dollar.svg";
import LoyaltyProgress from "../MyLoyalty/LoyaltyProgress";
import { convertDate } from "../shared/util/utils";
import { trackLoyaltyProgressLoad } from "../utils/gaTrackingFunctions";
import { useNavigate } from "react-router-dom";
import { getFullPath } from "../config/retailerConfig";

function LoyaltyProgressSummaryBlock(props) {
  const siteConfig = props.retailerConfig;
  const blockConfig = props.page.components.find(
    (component) => component.block_type === "LoyaltyProgressSummaryBlock"
  );
  const navigate = useNavigate()

  const user = useSelector((state) => state.user);
  const [userInfo, setUserInfo] = useState(user?.userDetails);
  const [loyaltyProgram, setLoyaltyProgram] = useState();
  const isCashback = siteConfig?.is_cashback && siteConfig?.site_type === 'MyLoyalty';

  useEffect(() => {
    getLoyaltyProgramDetails();
    setUserInfo(JSON.parse(localStorage.getItem("userData")));
  }, []);

  const lifetimePoints = isCashback ? loyaltyProgram?.lifetimePoints : 0;
  const threshold = isCashback ? loyaltyProgram?.rewardGroups[0]?.targetThreshold : 0;
  const rewardsEarned = isCashback ? Math.floor(lifetimePoints / threshold) : 0;
  const rewardValue = isCashback ? loyaltyProgram?.rewardGroups[0]?.rewards[0]?.rewardValue : 0;
  const totalEarned = isCashback ? rewardsEarned * rewardValue : 0;

  const getLoyaltyProgramDetails = async () => {
    const dpnData = isCashback 
      ? siteConfig.dpn_instance 
      : siteConfig.retailer_dpn_instance;

    const response = await getProgramDetails(
      dpnData.dpn_instance,
      dpnData.loyalty_program_id,
      localStorage.getItem("retailerToken")
    );
    if (!response.error) {
      if (response) {
        setLoyaltyProgram(response);
        trackLoyaltyProgressLoad(true);
      }
    } else {
      trackLoyaltyProgressLoad(false);
      setError("Error");
    }
  };

  // Scroll to anchor if # is present or navigate to URL if # is not present
  function handleNavigation(link) {
    if (link.startsWith('#')) {
      const elementId = link.slice(1)
      const element = document.getElementById(elementId)
      element?.scrollIntoView({ behavior: 'smooth' })
    } else {
      navigate(getFullPath(link))
    }
  }

  const [error, setError] = useState(null);

  const useStyles = makeStyles((theme) => ({
    lpsContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "0 12%",
      paddingBottom: "80px",
      paddingTop: "80px",
      backgroundColor: blockConfig.background_color,
      backgroundImage: `url("${blockConfig?.background_image}")`,
      backgroundPosition: "bottom",

      [theme.breakpoints.down("1025")]: {
        paddingBottom: "40px",
        paddingTop: "40px"
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0 16px",
        paddingBottom: "40px",
        paddingTop: "40px"
      },
    },
    headerSec: {
      marginTop: "48px",
      marginBottom: "48px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",

      [theme.breakpoints.down("sm")]: {
        marginTop: "40px",
        marginBottom: "32px",
      },
    },
    header: {
      fontFamily: "switzer",
      fontStyle: "normal",
      fontSize: "90px",
      fontWeight: "800",
      lineHeight: "81px",
      marginBottom: "16px",
      color: blockConfig.text_color,

      [theme.breakpoints.down("sm")]: {
        marginBottom: "4px",
        fontSize: "42px",
        lineHeight: "30px",
      },
    },
    subHeader: {
      ontFamily: "Switzer",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "42px",
      color: blockConfig.text_color,
      lineHeight: "46.2px",
      opacity: ".6",

      [theme.breakpoints.down("sm")]: {
        fontSize: "30px",
        lineHeight: "30px",
      },
    },
    progressBarCtn: {
      border: "1px solid #b6b8c3",
      background: "#fff",
      borderRadius: "24px",
      padding: "48px",
      width: "100%",

      "& .progress-ctn": {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "flex-start",

        "& .progressbar-section": {
          width: "100% !important",
          margin: "0 !important",
        },

        "& .bottomRightLabel": {
          display: "none",
          fontWeight: "400",
          fontFamily: "switzer",
          width: "100%",

          [theme.breakpoints.down("sm")]: {
            display: "flex",
            justifyContent: "flex-start",
            fontSize: "14px",
            padding: "0 0 4px",
          },
        },

        "& .pgLabel": {
          display: "flex",
          fontSize: "18px",
          justifyContent: "space-between",
          fontFamily: "switzer",
          padding: "0 0 8px",
          width: "100%",

          "& .rightLabel": {
            fontWeight: "400 !important",
          },

          [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            fontWeight: "700",
            padding: "0 0 4px",

            "& .rightLabel": {
              display: "none",
            },
          },
        },
      },

      [theme.breakpoints.down("sm")]: {
        padding: "24px",

        "&.bottomRightLabel": {
          display: "flex !important",
        },
      },
    },
    programTitleSec: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "16px",

      "& .programDesc": {
        fontSize: "42px",
        fontFamily: "switzer",
        marginBottom: "4px",
        fontWeight: "700",
        lineHeight: "46.2px",

        [theme.breakpoints.down("sm")]: {
          fontSize: "24px",
          lineHeight: "26.4px",
        },
      },

      "& .dateLabel": {
        display: "flex",
        fontSize: "18px",
        fontFamily: "switzer",
        fontWeight: "400",
        opacity: ".6",
        lineHeight: "27px",

        [theme.breakpoints.down("sm")]: {
          fontSize: "14px",
          lineHeight: "21px",
        },
      },

      "& .badge": {
        fontSize: "18px",
        fontFamily: "switzer",
        fontWeight: "700",
        lineHeight: "27px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "4px",
        color: blockConfig.progressbar_color,
        background: blockConfig.secondary_color,
        display: "flex",
        height: isCashback ? "32px" : "30px",
        padding: "4px 16px",
        marginTop: "8px",
        maxWidth: "160px",
        minWidth: isCashback ? "89px" : "138px",
        // minWidth: "138px",

        [theme.breakpoints.down("sm")]: {
          fontSize: "14px",
          padding: "2px 8px",
          minWidth: "initial",
          maxWidth: "100px",
          marginTop: "0",
          marginBottom: "4px",
          marginBottom: "4px",
        },
      },

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column-reverse",
      },
    },
    rewardsInfoSection: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "16px",
      padding: "24px",
      alignItems: "center",
      background: "#F8F9FA",
      borderRadius: "12px",

      [theme.breakpoints.down("md")]: {
        padding: "26px 20px",
      },

      [theme.breakpoints.down("xs")]: {
        padding: "16px",
      },
    },
    couponInfo: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      margin: "0 16px",

      "& .label": {
        fontSize: "18px",
        fontFamily: "switzer",
        fontWeight: "700",
        marginBottom: "4px",
        lineHeight: "27px",
      },

      [theme.breakpoints.down("md")]: {
        margin: "0 10px",

        "& .label": {
          marginBottom: "4px",
          fontSize: "16px",
          lineHeight: "21px",
        },
      },

      [theme.breakpoints.down("sm")]: {
        margin: "0 8px",

        "& .label": {
          marginBottom: "4px",
          fontSize: "14px",
          lineHeight: "21px",
        },
      },
    },
    avatarBg: {
      width: "58px !important",
      height: "58px !important",
      background: `${blockConfig.secondary_color} !important`,

      "& svg": {
        fill: blockConfig.progressbar_color,
      },

      [theme.breakpoints.down("md")]: {
        width: "42px !important",
        height: "42px !important",

        "& svg": {
          width: "22px",
        },
      },

      [theme.breakpoints.down("sm")]: {
        width: "40px !important",
        height: "40px !important",

        "& svg": {
          width: "18px",
        },
      },
    },
    dotLabel: {
      height: "24px",
      width: "24px",
      borderRadius: "50%",
      marginRight: "8px",
      marginBottom: "0",

      "&:nth-child(1)": {
        paddingTop: "0",
      },

      [theme.breakpoints.down("md")]: {
        height: "18px",
        width: "18px",
      },

      [theme.breakpoints.down("sm")]: {
        height: "16px",
        width: "16px",
      },
    },
    detailsBtn: {
      border: `1px solid ${blockConfig.progressbar_color}`,
      color: blockConfig.progressbar_color,
      background: "#fff",
      fontFamily: "switzer",
      fontSize: "18px",
      fontWeight: "800",
      textWrap: "nowrap",
      textTransform: "initial",
      padding: "8px 32px",
      borderRadius: "240px",
      lineHeight: "27px",
      minWidth: "169px",

      "&:hover": {
        background: "#fff",
        opacity: ".8",
      },

      "@media (min-width:1481px) and (max-width:1600px)": {
        padding: "8px 24px",
        lineHeight: "21px",
        minWidth: "148px",
      },

      "@media (min-width:1279px) and (max-width:1480px)": {
        padding: "8px 16px",
        lineHeight: "21px",
        minWidth: "auto",
      },

      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        width: "100%",
        lineHeight: "21px",
        fontWeight: "700",
        padding: "4px 20px",
        minWidth: "auto",
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        width: "100%",
        lineHeight: "15.4px",
        fontWeight: "700",
        minWidth: "auto",
        padding: "12px 20px",
      },
    },
    newRewardsSection: {
      marginTop: "16px",
    },
    rewardCard: {
      padding: "24px",
      background: "#F8F9FA",
      borderRadius: "16px",
      height: "100%",
    },
    rewardContent: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    rewardIconSection: {
      display: "flex",
      alignItems: "center",
      gap: "16px",
    },
    rewardInfo: {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
    },
    rewardLabel: {
      fontSize: "18px",
      fontFamily: "switzer",
      fontWeight: "700",
      lineHeight: "27px",
      color: "#25272C",
      
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
    dotContainer: {
      display: "flex",
      gap: "8px",
      alignItems: "center",
    },
    newDotLabel: {
      width: "20px",
      height: "20px",
      borderRadius: "9999px",
      
      [theme.breakpoints.down("sm")]: {
        width: "16px",
        height: "16px",
      },
    },
    cashbackAmount: {
      fontSize: "18px",
      fontFamily: "switzer",
      fontWeight: "400",
      lineHeight: "27px",
      color: "#25272C",
    },
    navBtn: {
      padding: "8px 32px",
      fontFamily: "Switzer",
      background: "white",
      boxShadow: "0px 3px 5px rgba(107, 114, 128, 0.15)",
      borderRadius: "240px",
      border: `1px solid ${blockConfig.progressbar_color}`,
      color: blockConfig.progressbar_color,
      fontSize: "18px",
      fontWeight: "700",
      textTransform: "none",
      whiteSpace: "nowrap",
      
      "&:hover": {
        background: "#fff",
        opacity: ".8",
      },
      "&:focus": {
        outline: "none",
      },
      
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        padding: "8px 24px",
      },
    },
  }));
  const classes = useStyles();



  return (
    <div className={classes.lpsContainer}>
      <div className={classes.headerSec}>
        <div className={classes.header}>
          Hello, {' '}
          {userInfo !== undefined && userInfo !== null
            ? userInfo?.firstName
            : user?.userDetails?.firstName}
        </div>
        <div className={classes.subHeader}>{blockConfig.sub_header}</div>
      </div>
      
      {/* Summary Block */}
      {loyaltyProgram ? (
        <div className={classes.progressBarCtn}>
          <div className={classes.programTitleSec}>
            <div>
              <div className="programDesc">
                {loyaltyProgram?.description}
              </div>
              <span className="dateLabel">
                {isCashback ? 'Exp.' : 'Program ends'} {convertDate(loyaltyProgram?.qualifyingOffers[0]?.expirationDate)}
              </span>
            </div>
            <div></div>
            <div>
              <div className="badge">
                {isCashback ? (
                  `Earn ${loyaltyProgram.rewardGroups[0]?.rewards[0]?.offer?.clipLimitPerShopper}x`
                ) : (
                  `Max: ${loyaltyProgram.rewardGroups[0]?.rewards[0]?.offer?.clipLimitPerShopper} ${
                    loyaltyProgram.rewardGroups[0]?.rewards[0]?.offer?.clipLimitPerShopper > 1 ? "times" : "time"
                  }`
                )}
              </div>
            </div>
          </div>

          {/* ProgressBar */}
          <LoyaltyProgress
            isLoySyncProgress={true}
            offerInfo={loyaltyProgram}
            height={32}
            color={`linear-gradient(to right, ${blockConfig.progressbar_color}, ${blockConfig.progressbar_color})`}
            indicatorColor={blockConfig.progressbar_color}
          />

          {/* Rewards section */}
          {isCashback ? (
            <div className={classes.newRewardsSection}>
              <Grid container spacing={3}>
                {/* Transactions Section */}
                <Grid item xs={12} md={6}>
                  <div className={classes.rewardCard}>
                    <div className={classes.rewardContent}>
                      <div className={classes.rewardIconSection}>
                        <Avatar className={classes.avatarBg}>
                          <GiftIcon current={blockConfig.progressbar_color} />
                        </Avatar>
                        <div className={classes.rewardInfo}>
                          <div className={classes.rewardLabel}>Rewards earned</div>
                            <div className={classes.dotContainer}>
                              {/* TODO, use maxlifetimepoints instead of hardcoded value! */}
                              {[...Array(5)].map((x, idx) => (
                                <div
                                  className={classes.newDotLabel}
                                  style={{
                                    background: idx < rewardsEarned ? blockConfig.progressbar_color : "#B6BAC3"
                                  }}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                        <Button 
                          variant="outlined"
                          className={classes.navBtn}
                          onClick={() => handleNavigation(blockConfig.rewards_button_link)}
                          style={{ minWidth: '135px' }}
                        >
                          {blockConfig.rewards_button_text}
                        </Button>
                    </div>
                  </div>
                </Grid>

                {/* Cashback Section */}
                <Grid item xs={12} md={6}>
                  <div className={classes.rewardCard}>
                    <div className={classes.rewardContent}>
                      <div className={classes.rewardIconSection}>
                        <Avatar className={classes.avatarBg}>
                          <DollarIcon current={blockConfig.progressbar_color} />
                        </Avatar>
                        <div className={classes.rewardInfo}>
                          <div className={classes.rewardLabel}>Cashback earned</div>
                          <div className={classes.cashbackAmount}>
                            ${(totalEarned / 100).toFixed(2)}
                          </div>
                        </div>
                      </div>
                        <Button 
                          variant="outlined"
                          className={classes.navBtn}
                          onClick={() => handleNavigation(blockConfig.cashback_button_link)}
                        >
                          {blockConfig.cashback_button_text}
                        </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div className={classes.rewardsInfoSection}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={5} lg={5} zeroMinWidth>
                  <div style={{ display: "flex" }}>
                    <div>
                      <Avatar className={classes.avatarBg}>
                        <GiftIcon
                          current={blockConfig.progressbar_color}
                        />
                      </Avatar>
                    </div>

                    <div className={classes.couponInfo}>
                      <div className="label">Coupons earned</div>
                      <div>
                        {[
                          ...Array(
                            loyaltyProgram.rewardGroups[0]?.rewards[0]?.offer?.clipLimitPerShopper
                          ),
                        ].map((x, idx) => (
                          <label
                            className={classes.dotLabel}
                            style={{
                              background:
                                loyaltyProgram?.rewardGroups[0]?.rewards[0]?.offer
                                  .clippedDates?.length > idx
                                  ? blockConfig.progressbar_color
                                  : "#B6BAC3",
                            }}
                          ></label>
                        ))}
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={5} lg={5} zeroMinWidth>
                  <div style={{ display: "flex" }}>
                    <div>
                      <Avatar className={classes.avatarBg}>
                        <BadgeCheckIcon
                          current={blockConfig.progressbar_color}
                        />
                      </Avatar>
                    </div>

                    <div className={classes.couponInfo}>
                      <div className="label">Coupons redeemed</div>
                      <div>
                        {loyaltyProgram?.rewardGroups[0]?.rewards[0]?.offer
                          ?.clippedDates?.length > 0 ? (
                          <>
                            {" "}
                            {loyaltyProgram?.rewardGroups[0]?.rewards[0]?.offer.clippedDates.map(
                              (n, idx) => (
                                <label
                                  className={classes.dotLabel}
                                  style={{
                                    background:
                                      loyaltyProgram?.rewardGroups[0]?.rewards[0]
                                        ?.offer?.redeemedDates?.length > idx
                                        ? blockConfig.progressbar_color
                                        : "#B6BAC3",
                                  }}
                                ></label>
                              )
                            )}
                          </>
                        ) : (
                          <>
                            <label className={classes.dotLabel}>
                              &nbsp;{" - "}
                            </label>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  zeroMinWidth
                  style={{ textAlign: "right", display: "flex", alignItems: "center" }}
                >
                  <Button  
                    onClick={() => {
                      const anchorId = blockConfig.view_details_anchor_id;
                      const targetElement = document.getElementById(anchorId);
                      if (targetElement) {
                        window.scrollTo({ top: targetElement.offsetTop - 200, behavior: 'smooth' });
                      }
                    }}
                    className={classes.detailsBtn}
                    variant="outlined"
                  >
                    View details
                  </Button>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      ) : (
        <>Loading...</>
      )}
    </div>
  );
}

export default LoyaltyProgressSummaryBlock;
