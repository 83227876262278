import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import LoyalityOffer from "../blocks/LoyalityProgram/LoyalityOffer";
import {
  getAvailableProgramDetails,
  enrollProgram,
} from "../service/myloyalty_services";
import { shouldRenderComponent } from "../shared/util/ComponentAuth";

function RecommendedProgrms(props) {
  const retailerConfig = useSelector((state) => state.config.retailerConfig);
  const [availablePrograms, setAvailablePrograms] = useState([]);
  const siteConfig = props.props;
  const recommended = siteConfig.components.find(
    (component) => component.block_type === "RecommendedProgramsPreviewBlock"
  );

  const useStyles = makeStyles((theme) => ({
    itemContainer: {
      padding: "24px",
      justifyContent: "center",
      [theme.breakpoints.down("xs")]: {
        padding: "48px 16px",
        justifyContent: "flex-start",
      },
    },
    gridItem: {
      margin: "8px 8px",
      alignItems: "center",

      "& .btnContainer": {
        padding: "0",
      },

      "& .MuiCard-root": {
        border: ".5px solid #e2dcdc",
        borderRadius: "10px",
        background: "#FFF",
        boxShadow:
          "0px 38px 11px 0px rgba(29, 60, 109, 0.00), 0px 24px 10px 0px rgba(29, 60, 109, 0.01), 0px 14px 8px 0px rgba(29, 60, 109, 0.05), 0px 6px 6px 0px rgba(29, 60, 109, 0.09), 0px 2px 3px 0px rgba(29, 60, 109, 0.10), 0px 0px 0px 0px rgba(29, 60, 109, 0.10)",

        "& .MuiButton-root": {
          height: "56px",
          margin: "0",
          fontSize: "18px",
          borderRadius: "0",
        },
      },
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const retailer = retailerConfig.dpn_instance.dpn_instance;
    getAvailableProgram(token, retailer);
  }, []);

  function getAvailableProgram(token, retailer) {
    const getPrograms = getAvailableProgramDetails(
      token,
      retailer,
      "available"
    );
    getPrograms
      .then((res) => {
        setAvailablePrograms(res.items);
      })
      .catch((err) => console.log(err));
  }

  const enrollCurrentProgram = async (id) => {
    const token = localStorage.getItem("token");
    const retailer = retailerConfig.dpn_instance.dpn_instance;

    const response = await enrollProgram(
      retailerConfig.dpn_instance.dpn_instance,
      id
    );
    if (!response.error) {
      if (response.result) {
        getAvailableProgram(token, retailer);
      }
    } else {
      console.log("error occurred on api call");
    }
  };

  if (!shouldRenderComponent(recommended.auth_visibility)) {
    return null;
  }

  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="stretch"
        className={classes.itemContainer}
      >
        <div></div>
        {availablePrograms &&
          availablePrograms.length > 0 &&
          availablePrograms.map((item, idx) => (
            <Grid
              item
              key={`loyality-program-${idx}`}
              className={classes.gridItem}
              xs={6}
              sm={6}
              md={3}
              lg={3}
            >
              {" "}
              <LoyalityOffer
                program={item}
                classNames="btnContainer"
                enrollCurrentProgram={enrollCurrentProgram}
                retailerConfig={retailerConfig}
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );
}

export default RecommendedProgrms;
