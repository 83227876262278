import React, { useState } from 'react';
import './informationBanner.scss';
import shop from './shopping-bag.svg';
import gift from './gift.svg';
import repeat from './repeat.svg';
import QualifyingList from '../qualifyingProducts';

function InformationBanner(page) {
  const block = page.page.components.find(component => component.block_type === 'QualifyingProductsBlock');
  const [showQualifyingProducts, setShowQualifyingProducts] = useState(false);

  function trackQualifyingProductsEvent() {
    console.log('qualifyingProducts event tracked');
    window.gtag('event', 'open_qualifying_products', {
      version: localStorage.getItem("AB_TEST_VARIABLE") || 'N/A',
    });
  }

  const handleQualifyingClose = () => {
    setShowQualifyingProducts(false);
  };

  const handleShowQualifyingProducts = () => {
    setShowQualifyingProducts(true);
    trackQualifyingProductsEvent();
  };

  return (
    <>
      <div className='information-banner-container' style={{ backgroundColor: block.color_background }}>
        <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap" rel="stylesheet" />
        <div className='information-banner-left'>
          <div className='information-banner-heading' style={{ color: block.color_title }}>
            {block.qualifying_products_title}
          </div>
          <div id='view-qualifying-products-button'>
            <button className="qp-custom-button" onClick={handleShowQualifyingProducts} style={
              // { color: block.color_button_text, backgroundColor: block.color_button_bg }
            {color: '#8757FF', backgroundColor: "white"}
              }>
              {block.qualifying_products_button_text}
            </button>
          </div>
        </div>
        <div className='information-banner-right'>
          <div className='information-list-bullet-column'>
          <div className='information-list-bullet'>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <div className="icon-container" style={{ backgroundColor: block.color_icons_background, borderRadius: 50, padding: 10, width: 50, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <img src='images/shopping.png' alt="Shop Icon"/>
    </div>
  </div>
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <div className='information-list-bullet-header' style={{ color: block.color_headers }}>{block.loyalty_process_step_1_heading}</div>
    <div className='information-list-bullet-description' dangerouslySetInnerHTML={{ __html: block.loyalty_process_step_1 }} />
  </div>
</div>
</div>
<div className='information-list-bullet'>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <div className="icon-container" style={{ backgroundColor: block.color_icons_background, borderRadius: 50, padding: 10, width: 50, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <img src='images/cart.png' alt="Cart Icon"/>
    </div>
  </div>
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <div className='information-list-bullet-header' style={{ color: block.color_headers }}>{block.loyalty_process_step_2_heading}</div>
    <div className='information-list-bullet-description' dangerouslySetInnerHTML={{ __html: block.loyalty_process_step_2 }} />
  </div>
</div>
</div>
<div className='information-list-bullet'>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <div className="icon-container" style={{ backgroundColor: block.color_icons_background, borderRadius: 50, padding: 10, width: 50, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <img src='images/gift.png' alt="Gift Icon"/>
    </div>
  </div>
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <div className='information-list-bullet-header' style={{ color: block.color_headers }}>{block.loyalty_process_step_3_heading}</div>
    <div className='information-list-bullet-description' dangerouslySetInnerHTML={{ __html: block.loyalty_process_step_3 }} />
  </div>
</div>
</div>
          </div>
        </div>
      </div>
      {/* <div className="divider"> */}
        {/* <div className="divider-section red"></div>
        <div className="divider-section blue"></div>
        <div className="divider-section brown"></div>
        <div className="divider-section green"></div> */}
        {showQualifyingProducts && <QualifyingList show={showQualifyingProducts} handleClose={handleQualifyingClose} page={page.page} />}
      {/* </div> */}
    </>
  );
}

export default InformationBanner;
