import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import ProgramPod from "./ProgramPod";
import LoyalityOffer from "../blocks/LoyalityProgram/LoyalityOffer";
import { getAvailableProgramDetails } from "../service/myloyalty_services";
import { makeStyles } from "@material-ui/core/styles";

function EnrolledPrograms({ props }) {
  const retailerConfig = useSelector((state) => state.config.retailerConfig);
  const useStyles = makeStyles((theme) => ({
    itemContainer: {
      padding: "24px",
      justifyContent: "center",
      [theme.breakpoints.down("xs")]: {
        padding: "48px 16px",
        justifyContent: "flex-start",
      },
    },
    gridItem: {
      margin: "8px 8px",
      alignItems: "center",

      "& .MuiCard-root": {
        border: ".5px solid #e2dcdc",
        borderRadius: "10px",
        background: "#FFF",
        boxShadow:
          "0px 38px 11px 0px rgba(29, 60, 109, 0.00), 0px 24px 10px 0px rgba(29, 60, 109, 0.01), 0px 14px 8px 0px rgba(29, 60, 109, 0.05), 0px 6px 6px 0px rgba(29, 60, 109, 0.09), 0px 2px 3px 0px rgba(29, 60, 109, 0.10), 0px 0px 0px 0px rgba(29, 60, 109, 0.10)",

        "& .buttonContainer": {
          padding: "0",
        },

        "& .MuiButton-root": {
          height: "62px",
          margin: "0",
          fontSize: "18px",
          borderRadius: "0",
        },
      },
    },
  }));
  const classes = useStyles();
  const [enrolledPrograms, setEnrolledPrograms] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    const retailer = retailerConfig.dpn_instance.dpn_instance;
    const getPrograms = getAvailableProgramDetails(
      token,
      retailer,
      "clipped, redeemed"
    );
    getPrograms
      .then((res) => {
        setEnrolledPrograms(res.items);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="stretch"
        className={classes.itemContainer}
      >
        {enrolledPrograms &&
          enrolledPrograms.length > 0 &&
          enrolledPrograms.map((item, i) => (
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={3}
              key={i}
              className={classes.gridItem}
            >
              {" "}
              <LoyalityOffer program={item} className="offerProd" />
            </Grid>
          ))}
      </Grid>
    </div>
  );
}

export default EnrolledPrograms;
