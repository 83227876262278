import { SET_USER_LOGIN_STATUS, SET_LOYALITY_POINTS, USER_DETAILS } from "../reducers/user";
import { SET_RETAILER_CONFIG } from '../reducers/config';
import { getUserPoints }  from "../../service/account_service";
import { getProgramDetails }  from "../../service/myloyalty_services";


const accountActions = {
  updateUserLytPoints(retailer) {

    return async (dispatch) => {
      try {
        const cashbackBalances = await getUserPoints(retailer);
        const totalPoints = cashbackBalances?.reduce((sum, item) => {
          if (item.redemptionType === "external" || item.redemptionType === "unrestricted") {
            return sum + item.balance;
          }
          return sum;
        }, 0);
        dispatch(SET_LOYALITY_POINTS({ points: totalPoints }));
      } catch (error) {
        console.error('Error in updateUserLytPoints:', error);
      }
    };
  },

  updateUserSpendablePoints(retailer, retailerConfig) {
    return async (dispatch) => {
      try {
        const response = await getProgramDetails(
          retailerConfig.dpn_instance.dpn_instance,
          retailerConfig.dpn_instance.loyalty_program_id,
          localStorage.getItem("retailerToken")
        );

        const targetBalance = await response.targetBalance;      
        dispatch(SET_LOYALITY_POINTS({ points: targetBalance }));
      } catch (error) {
        console.error('Error in updateUserSpendablePoints:', error);
      }
    };
  },

  setUserLoginStatus() {
    return async (dispatch) => {
      dispatch(SET_USER_LOGIN_STATUS());
    };
  },

  setUserDetails(data) {
    return async (dispatch) => {
      dispatch(USER_DETAILS(data));
    };
  },

  serRetailerConfig(config) {
    return async (dispatch) => {
      dispatch(SET_RETAILER_CONFIG(config));
    };
  }
};

export default accountActions;
