import ProgramExplanation from "../../programExplanation/programExplanation";
import EnrolledPrograms from "../../MyLoyalty/EnrolledPrograms";
import RecommendedProgrms from "../../MyLoyalty/RecommendedProgrms";
import OffersBlock from "../../offerBlock/index";
import ChatbotButton from "../../Button/index";
import ContactBlock from '../../contact/index';
import GardenImages from "../../logoGarden/index";
import InstagramSection from "../../instagram/index";
import TikTokSection from "../../tiktok/index";
import Rebates from "../../rebates/index";
import Chatbot from "../../chatbot/index";
import FAQBlock from "../../faq/index";
import FreeText from "../../freeText/index";
import LoyaltyHeader from "../../blocks/loyaltyHeader";
import SpendablePointsHeader from "../../blocks/spendablePointsHeader";
import Hero from "../../hero";
import LoyaltyBlock from "../../loyalty/index";
import LoyalityProgramGrid from "../../blocks/LoyalityProgram/LoyalityProgramsGrid";
import SpendablePointsGridBlock from "../../blocks/SpendablePtsProgram/SpendablePtsProGrid";
import InformationBanner from "../../informationBanner/index";
import Cart from "../../cart/index";
import Recipes from "../../recipes/index";
import SweepstakesEmbed from '../../sweepstakesEmbed/sweepstakesEmbed';
import OfferCarousel from "../../offerCarousel/offerCarousel";
import MetaDataBlock from "../../metadata/index";
import WebcamCapture from "../../UploadReceipt/WebcamCapture";
import ShoppingHistoryBlock from "../../shoppingHistory/index";
import ParticipatingProductsBlock from "../../participatingProducts";
import LoyaltyProgressSummaryBlock from "../../blocks/loyaltyProgressSummaryBlock";
import CashoutBlock from "../../blocks/Cashout/cashoutBlock";
import RewardsSummaryBlock from "../../rewardsSummary";

const getComponent = (blockType, pageConfig, blockConfig, retailerConfig) => {
  const blockToComponentMap = {
    AddToCartBlock: <Cart component={blockConfig} />,
    HeaderBlock:
    retailerConfig.site_type === "MyLoyalty" ? (
      retailerConfig.loyaltyPoints_display === "POINTS" ? (
        <SpendablePointsHeader props={pageConfig} site_Config={retailerConfig} />
      ) : (
        <LoyaltyHeader props={pageConfig} site_Config={retailerConfig} />
      )
    ) : (
      <Hero page={pageConfig} />
    ),
    ProgramExplanationBlock: <ProgramExplanation page={pageConfig} />,
    RecommendedProgramsPreviewBlock: <RecommendedProgrms props={pageConfig} />,
    EnrolledProgramsPreviewBlock: <EnrolledPrograms props={pageConfig} />,
    OfferBlock: <OffersBlock page={pageConfig} />,
    LoyaltyProgramGridBlock: <LoyalityProgramGrid page={pageConfig} />,
    SpendablePointsGridBlock: <SpendablePointsGridBlock page={pageConfig}/>,
    LogoGardenBlock: <GardenImages component={blockConfig} />,
    InstagramBlock: <InstagramSection component={blockConfig} />,
    TikTokBlock: <TikTokSection component={blockConfig} />,
    ChatbotButtonBlock: <ChatbotButton component={blockConfig} />,
    ContactBlock: <ContactBlock page={pageConfig} retailerConfig={retailerConfig} />,
    Rebates: <Rebates page={pageConfig} />,
    ChatbotBlock: <Chatbot page={pageConfig} />,
    FAQBlock: <FAQBlock component={blockConfig} />,
    LoyaltyBlock: <LoyaltyBlock page={pageConfig} />,
    QualifyingProductsBlock: <InformationBanner page={pageConfig} />,
    RecipeBlock: <Recipes page={pageConfig} />,
    OfferCarouselBlock: <OfferCarousel component={blockConfig} page={pageConfig} />,
    MetaDataBlock: <MetaDataBlock page={pageConfig} />,
    UploadReceiptBlock: <WebcamCapture page={pageConfig} retailerConfig={retailerConfig} component={blockConfig} />, 
    FreeTextBlock: <FreeText component={blockConfig} />,
    SweepstakesBlock : <SweepstakesEmbed component={blockConfig} />,
    ShoppingHistoryBlock: <ShoppingHistoryBlock page={pageConfig} />,
    ParticipatingProductsBlock: <ParticipatingProductsBlock page={pageConfig}/>,
    LoyaltyProgressSummaryBlock: <LoyaltyProgressSummaryBlock page={pageConfig} retailerConfig={retailerConfig}/>,
    CashoutBlock: <CashoutBlock page={pageConfig} retailerConfig={retailerConfig}/>,
    RewardsSummaryBlock:<RewardsSummaryBlock page={pageConfig} retailerConfig={retailerConfig}/>
  };

  return blockToComponentMap[blockType];
};

export const getPageBySlug = (siteConfig, slug) => {
  if (!siteConfig || !Array.isArray(siteConfig)) {
    console.error('Invalid siteConfig');
    return null;
  }

  const page = siteConfig.find(pageObj => pageObj.slug === slug);
  
  if (!page) {
    console.warn(`No page found with slug: ${slug}`);
    return null;
  }

  return page;
};

export default getComponent;
