import React, { useEffect, useState } from "react";
import {
  makeStyles,
  useMediaQuery,
  createTheme,
  Button,
  FormControl,
  FormHelperText,
  TextField,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  List,
} from "@material-ui/core";
import "react-responsive-modal/styles.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  getAccountData,
  createPayPalAccount,
  makePayPalPayment,
  updatePayPalAccountStatus,
} from "../../service/account_service";
import { ReactComponent as DeleteIcon } from "../../assets/images/svg/deleteMobileIcon.svg";
import { EmailRgx } from "../../shared/util/constants";

export function PaymentModal(props) {
  const {
    retailer,
    transferAmount,
    payPalImg,
    blockTheme,
    onClose,
    onPaymentSuccess,
  } = props;
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState(null);
  const [payPalAccounts, setpayPalAccounts] = useState([]);
  const [email, setEmail] = useState(null);
  const [paymentSuccess, setpaymentSuccess] = useState(false);
  const successIcon = (
    <CheckCircleOutlineIcon
      style={{ fill: "#008000", width: "180px", height: "180px" }}
    />
  );

  const useStyles = makeStyles((theme) => ({
    rootCtn: {
      minWidth: "460px",
      margin: "0 auto",

      [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
      },
    },
    modalTitle: {
      fontSize: "20px",
      fontWeight: "400",
      display: "flex",
      flexDirection: "column",
      fontFamily: "switzer",
      alignItems: "center",
      marginBottom: "2rem",

      [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
        fontSize: "18px",
      },
    },
    modalBody: {
      minHeight: "330px",
    },
    inputLabel: {
      fontSize: "14px",
      lineHeight: "19.8px",
      fontFamily: "switzer",
      color: "#212931",
      fontWeight: "700",
      marginLeft: 0,
    },
    formInputControl: {
      display: "flex !important",
      margin: "10px 0 !important",

      "& .MuiFormHelperText-root": {
        fontSize: "14px",
        color: "#212931",
        fontWeight: "700",
        marginLeft: 0,
      },
    },
    reviewSection: {
      marginBottom: "10rem",
    },
    closeBtn: {
      width: "140px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "27px",
      boxShadow:
        "0px 2px 4px 0px rgba(107, 114, 128, 0.12), 0px 3px 5px 0px rgba(107, 114, 128, 0.15)",
      padding: "16px",
      borderRadius: "240px",
      marginTop: "4rem",
      border: "1px solid #000",
      cursor: "pointer",
      textTransform: "capitalize",
    },
    btnSection: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "24px",

      "& .MuiButton-root": {
        borderRadius: "20px",
        fontFamily: "switzer",
        fontWeight: "600",
        minWidth: "98px",
        lineHeight: "32px",
        background: blockTheme ? blockTheme.primary_color : "#53128C",
        color: blockTheme?.button_text_color
          ? blockTheme.button_text_color
          : "#fff",
      },

      "& .Mui-disabled": {
        background: "#EDEEF1 !important",
        color: "#6d7b88 !important",
      },
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    getPaypalAccountHistory();
  }, []);

  const getPaypalAccountHistory = async () => {
    const response = await getAccountData(retailer);
    if (response && response?.length > 0) {
      // filtering the paypal account
      const paypalAccount = response.filter(
        (item) => item.accountType === "paypal"
      );

      // getting transactions for unique account ids
      const uniqueAccountEmails = paypalAccount.filter(
        (arr, index, self) =>
          index === self.findIndex((t) => t.username === arr.username)
      );

      if (uniqueAccountEmails.length < 1) {
        setpayPalAccounts([]);
      } else {
        setpayPalAccounts(uniqueAccountEmails);
      }
    }
  };

  const makePaymentForPaypal = async (source) => {
    setError(null);

    // Find if the account is existing account
    const account = payPalAccounts.find((item) => item.username === email);

    // If not existing account
    if (!account) {
      // Create new pay Pal account
      const createAccPayload = {
        username: email,
        description: `My ${source} account`,
        accountType: source,
        usernameType: "email",
      };
      const newAccountRes = await createPayPalAccount(
        retailer,
        createAccPayload
      );

      // Make payment
      if (newAccountRes.accountId) {
        const paymentPayload = {
          accountId: `${newAccountRes.accountId}`,
          amount: transferAmount,
        };
        const paymentStatus = await makePayPalPayment(retailer, paymentPayload);
        if (paymentStatus?.amount) {
          setpaymentSuccess(true);
          // Refresh the balance.
        } else {
          setError(
            JSON.parse(paymentStatus?.error?.request?.responseText)?.message
              ? JSON.parse(paymentStatus?.error?.request?.responseText)?.message
              : "Payment is failed, Please try again."
          );
        }
      } else {
        const errorMsg = JSON.parse(newAccountRes?.error?.request?.responseText)
          ?.message
          ? JSON.parse(newAccountRes?.error?.request?.responseText)?.message
          : "Unable to create account, Please chek your email";
        setError(errorMsg);
      }
    } else {
      if (!account.enabled) {
        // If account is suspend enable the account.
        const response = await updatePayPalAccountStatus(
          retailer,
          account.accountId,
          true
        );
        if (!response.error) {
          // making payment for existing emails
          makePayment(account.accountId, transferAmount);
        } else {
          setError("Payment is failed, Please check youe email.");
        }
      } else {
        makePayment(account.accountId, transferAmount);
      }
    }
  };

  const makePayment = async (aid, amount) => {
    const body = { accountId: aid, amount: amount };
    const paymentRes = await makePayPalPayment(retailer, body);
    if (paymentRes?.amount) {
      setpaymentSuccess(true);
    } else {
      setError(
        JSON.parse(paymentRes?.error?.request?.responseText)?.message
          ? JSON.parse(paymentRes?.error?.request?.responseText)?.message
          : "Payment is failed, Please try again."
      );
    }
  };

  const onDisablePayPalEmail = async (aid) => {
    const response = await updatePayPalAccountStatus(retailer, aid, false);
    if (!response.error) {
      getPaypalAccountHistory();
    } else {
      setError("Error");
    }
  };

  return (
    <div className={classes.rootCtn}>
      {!paymentSuccess && (
        <div container spacing={2} style={{ padding: isMobile ? "0" : "20px" }}>
          <div className={classes.modalTitle}>
            <img alt="PayPal Icon" src={payPalImg} style={{ width: "200px" }} />
            {currentStep === 1 ? "Account Details" : "Review and Transfer"}
          </div>

          <div className={classes.modalBody}>
            {/* Account(Email) details */}
            {currentStep === 1 && (
              <>
                <div className="newAccSection">
                  <FormControl
                    className={classes.formInputControl}
                    variant="outlined"
                  >
                    <label htmlFor="Email">
                      <FormHelperText
                        className={classes.inputLabel}
                        id="outlined-weight-helper-text"
                      >
                        Paypal Email Address
                      </FormHelperText>
                    </label>

                    <TextField
                      id="Email"
                      variant="outlined"
                      //   error={validations.error !== ""}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="PayPal linked email address"
                    />
                  </FormControl>
                </div>

                <div
                  className="existingAccSection"
                  style={{ marginTop: "18px" }}
                >
                  <label
                    className="subHeader"
                    style={{ fontSize: "14px", fontWeight: "700" }}
                  >
                    My Paypal Accounts
                  </label>

                  {/* previous list */}
                  <div>
                    <List
                      style={{
                        width: "100%",
                        maxHeight: "192px",
                        minWidth: "280px",
                        overflow: "scroll",
                        overflowX: "hidden",
                      }}
                    >
                      {payPalAccounts.length === 0 && (
                        <div
                          style={{
                            display: "flex",
                            margin: "2rem 0",
                            justifyContent: "center",
                          }}
                        >
                          No Recent Emails
                        </div>
                      )}
                      {payPalAccounts &&
                        payPalAccounts.length >= 1 &&
                        payPalAccounts.map((item) => (
                          <div id={item.username}>
                            {item.enabled == true && (
                              <ListItem
                                className={classes.listItem}
                                onClick={() => setEmail(item.username)}
                                style={{ padding: "6px", cursor: "pointer" }}
                              >
                                <ListItemText
                                  className={classes.listItemText}
                                  primary={item.username}
                                />
                                <ListItemSecondaryAction>
                                  <DeleteIcon
                                    onClick={() =>
                                      onDisablePayPalEmail(item.accountId)
                                    }
                                    style={{
                                      width: "21px",
                                      height: "21px",
                                      cursor: "pointer",
                                    }}
                                  />
                                  {item.id}
                                </ListItemSecondaryAction>
                              </ListItem>
                            )}
                          </div>
                        ))}
                    </List>
                  </div>
                </div>
              </>
            )}

            {/* Review and conformation */}
            {currentStep === 2 && (
              <div className={classes.reviewSection}>
                <FormControl
                  className={classes.formInputControl}
                  variant="outlined"
                >
                  <label htmlFor="Email">
                    <FormHelperText
                      className={classes.inputLabel}
                      id="outlined-weight-helper-text"
                    >
                      Email
                    </FormHelperText>
                  </label>

                  <TextField
                    id="Email"
                    variant="outlined"
                    value={email}
                    disabled={true}
                  />
                </FormControl>

                <FormControl
                  className={classes.formInputControl}
                  variant="outlined"
                >
                  <label htmlFor="Email">
                    <FormHelperText
                      className={classes.inputLabel}
                      id="outlined-weight-helper-text"
                    >
                      Amount
                    </FormHelperText>
                  </label>

                  <TextField
                    id="Email"
                    variant="outlined"
                    value={(transferAmount / 100).toFixed(2)}
                    disabled={true}
                    // onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>

                {error && (
                  <div
                    style={{
                      color: "#ff0000",
                      fontSize: "14px",
                      padding: "10px 0",
                      maxWidth: "400px",
                      textTransform: "capitalize",
                    }}
                  >
                    {error}
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Action Buttons */}
          {
            <div className={classes.btnSection}>
              {currentStep === 1 && (
                <Button
                  variant="contained"
                  style={{ marginRight: "10px", textTransform: "capitalize" }}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </Button>
              )}

              {currentStep !== 1 && (
                <Button
                  variant="contained"
                  style={{ marginRight: "10px", textTransform: "capitalize" }}
                  onClick={() => {
                    setCurrentStep(currentStep - 1);
                  }}
                >
                  Back
                </Button>
              )}

              <Button
                variant="contained"
                onClick={() =>
                  currentStep === 1
                    ? setCurrentStep(currentStep + 1)
                    : makePaymentForPaypal("paypal")
                }
                style={{
                  background: blockTheme ? blockTheme.primary_color : "#53128C",
                  color: blockTheme?.button_text_color
                    ? blockTheme.button_text_color
                    : "#fff",
                  textTransform: "capitalize",
                }}
                disabled={EmailRgx.test(email?.toLowerCase()) ? false : true}
              >
                {currentStep === 1 ? "Next" : "Transfer"}
              </Button>
            </div>
          }
        </div>
      )}

      {paymentSuccess && (
        <div
          className="paymentSuccessSection"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <div>{successIcon}</div>
          <label
            style={{
              fontSize: "18px",
            }}
          >
            Payment successfull completed.
          </label>

          <div className={classes.closeBtn} onClick={onPaymentSuccess}>
            Close
          </div>
        </div>
      )}
    </div>
  );
}

export default PaymentModal;
