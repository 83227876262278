import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MobileView from "./MobileView";
import DesktopView from "./DesktopView";
import { shouldRenderComponent } from "../shared/util/ComponentAuth";
import { default as duo_tone } from "../assets/images/svg/duo_tone.svg";
import QRCode from "react-qr-code";
import { getFullUrlPath } from '../config/retailerConfig';

function WebcamCapture({ component,props, retailerConfig }) {
  const [capturedImages, setCapturedImages] = useState([]);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const containerRef = useRef(null);
  const blockConfig = component
  const blockStyles = blockConfig?.blockstyle_set[0];

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const shouldFocus = params.get('focus') === 'webcam';

    if (shouldFocus) {
      const scrollTimeout = setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 1500);

      return () => clearTimeout(scrollTimeout);
    }
  }, [location]);

  function isMobile() {
    const minWidth = 820; // Custom width for desktop devices
    return window.innerWidth < minWidth || window.screen.width < minWidth;
  }

  const useStyles = makeStyles((theme) => ({
    container: {
      fontFamily:'Switzer, sans-serif',
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "80px 24px",
    },
    headerTextContainer: {
      fontFamily:'Switzer, sans-serif',
      display: "flex",
      justifyContent: "center",
      marginBottom: "64px",
      alignItems: "center",
      [theme.breakpoints.between("xs", "sm")]: {
        marginBottom: "32px",
      },
    },
    headerText: {
      fontFamily:'Switzer, sans-serif',
      fontSize: "91px",
      fontWeight: "800",
      lineHeight: "90%",
      maxWidth: "760px",
      color: blockStyles ? blockStyles?.text_color : "#000",
      textAlign: "center",
      letterSpacing: "-0.91px",
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "28px",
        fontWeight: "900",
      },
    },
    uploadBox: {
      fontFamily:'Switzer, sans-serif',
      position: 'relative',
      display: "flex",
      flexDirection: "column",
      height: "400px",
      border: `3px dashed ${blockStyles ? blockStyles?.button_background_color :"#0054A6"}`,
      justifyContent: "center",
      backgroundColor: blockStyles ? blockStyles?.background_color : "#E6EEF6",
      borderRadius: "24px",
      textAlign: 'center',
      [theme.breakpoints.between("xs", "sm")]: {
        minHeight: "340px",
      },
    },
    button: {
      fontFamily:'Switzer, sans-serif',
      borderRadius: "240px",
      border: `2px solid ${blockStyles ? blockStyles?.button_background_color :"#0073C5"}`,
      padding: '16px',
      backgroundColor: blockStyles ? blockStyles?.button_background_color : "#0073C5",
      width: "187px",
      boxShadow:
        "0px 38px 11px 0px rgba(29, 60, 109, 0.00), 0px 24px 10px 0px rgba(29, 60, 109, 0.01), 0px 14px 8px 0px rgba(29, 60, 109, 0.05), 0px 6px 6px 0px rgba(29, 60, 109, 0.09), 0px 2px 3px 0px rgba(29, 60, 109, 0.10), 0px 0px 0px 0px rgba(29, 60, 109, 0.10)",
      alignSelf: "center",
      fontSize: '18px',
      fontWeight: 700,
      color: blockStyles ? blockStyles?.button_text_color : "#fff",
      margin: '16px 0px',
      "&:hover":{
        backgroundColor: blockStyles ? blockStyles?.button_background_color : "#0073C5",
      },
      "&:active":{
        backgroundColor: blockStyles ? blockStyles?.button_background_color : "#0073C5",
      },
      "&:focus":{
        backgroundColor: blockStyles ? blockStyles?.button_background_color : "#0073C5",
        outline:'none'
      },
    },
    info: {
      fontFamily:'Switzer, sans-serif',
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "150%",
      color: blockStyles ? blockStyles?.text_color :  "#25272C",
      margin: "0",
      textAlign: "center",
    },
    innerContainer: {
      padding: '30px'
    },
    text: {
      fontFamily:'Switzer, sans-serif',
      position: 'absolute',
      backgroundColor: blockStyles ? blockStyles?.tab_background_color : '#E6EEF6',
      left: '49%',
      top: '50%'
    }
  }));
  const classes = useStyles();

  
  // Check visibility outside of hooks
  if (!shouldRenderComponent(component.auth_visibility)) {
    return null;
  }

  const handleOpen = () => {
    // openinf modal
    setOpen(true);
  };

  const handleClose = () => {
    //  closing modal
    setOpen(false);
  };

  const setImages = (imgSrc) => {
    // adding captured images to the state
    const data = imgSrc;
    capturedImages.push(data);
    setCapturedImages([...capturedImages]);
  };

  const uploadFiles = () => {
    // to be updated once api is build
    setCapturedImages([]);
  };

  const deleteImage = (index) => {
    // to delete captured images
    const updatedImages = capturedImages;
    updatedImages.splice(index, 1);
    setCapturedImages([...updatedImages]);
  };
  const qrCodeValue = getFullUrlPath('/login?focus=webcam');

  return (
    <div className={classes.container}>
      {/* handling view for mobile and desktop */}
      {
        isMobile() ?
          (<MobileView
            successMessage= {component.success_text_Msg}
            retailerConfig={retailerConfig}
            blockStyles={blockStyles}
            open={open}
            handleClose={handleClose}>
          </MobileView>) :
            (<DesktopView successMessage= {component.success_text_Msg} retailerConfig={retailerConfig} 
            blockStyles={blockStyles}
            open={open} handleClose={handleClose}></DesktopView>)
      }
      <div ref={containerRef} className={classes.headerTextContainer}>
        <h1 id={component.anchor_id} className={classes.headerText}>Upload Receipts to Earn Points</h1>
      </div>

      {/* handling front page for  mobile */}
      {isMobile() &&
        <div>
          <Button
            className={classes.button}
            style={{
              backgroundColor: blockStyles ? blockStyles?.button_background_color : "#0073C5",
              color: "#fff",
            }}
            onClick={handleOpen}
          >
            Take a Photo
          </Button>
          <p style={{ margin: '32px 16px', color: '#6B7280' }}>
            1 receipt per submission
          </p>
        </div>
      }

      {/* handling front page for desktop */}
      {!isMobile() &&
        <div className={classes.uploadBox}>
          <div className={classes.text}>OR</div>
          <Grid container>
            <Grid item xs={6} className={classes.innerContainer}>
              <div style={{ minHeight: '200px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={duo_tone}></img>
                </div>


                <Button className={classes.button} onClick={handleOpen}>
                  Take Photo
                </Button>
                <p style={{ margin: '12px 16px', color: blockStyles ? blockStyles?.secondary_color: '#6B7280', textAlign: 'center' }}>
                  1 receipt per submission
                </p>
              </div>
              <p className={classes.info}>
                Using your browser, click on the button to scan your receipt using your webcam
              </p>
            </Grid>

            <Grid item xs={6} className={classes.innerContainer} style={{ borderLeft: `1px solid ${blockStyles ? blockStyles?.primary_color: "#010101"} ` }}>
              <div style={{ maxHeight: '200px', maxWidth: '200px', margin: '0 auto' }}>
                <QRCode
                  value={qrCodeValue}
                  size={200}
                  style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                  viewBox={`0 0 200 200`}
                />
              </div>

              <p className={classes.info}>
                Scan the QR Code to access your account on mobile. Then, submit your receipt by taking a photo using your mobile camera.
              </p>
            </Grid>
          </Grid>
        </div>
      }
    </div>
  );
}

export default WebcamCapture;
