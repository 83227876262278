import * as React from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ProfileBody from "../blocks/Account/profile/index";
import ShippingAddress from "../blocks/Account/shipping";
import LogoutModal from "../blocks/Account/LogoutModel";
import ChangePassword from "../blocks/Account/ChangePassword";
import { CLEAR_USER } from "../redux/reducers/user";
import { makeStyles, createTheme, useMediaQuery, Typography } from "@material-ui/core";

export default function AccountPageMobile({}) {
  const retailerConfig = useSelector((state) => state.config.retailerConfig);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [openChangePwdModal, setOpenChangePwdModal] = useState(false);
  const colorTheme = retailerConfig.brand_design_theme;
  const [value, setValue] = useState("1");
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "0px 8px",
      borderColor: "transparent",
    },
    tabList:{
      margin:'0px 16px',
      "& span":{
        "&.MuiTabs-indicator":{
          height:'4px!important',
          backgroundColor:colorTheme.primary_color
        }
        
      }
    },
    tabPanel:{
      padding:'40px 16px!important',
      minHeight:'100vh',
    },
    tab: {
      fontFamily: "Switzer!important",
      fontWeight: "700!important",
      fontSize: "14px!important",
      lineHeight: "110%!important",
      padding: "12px!important",
      color:'#212931!important',
      textTransform: "capitalize!important",
      "&:hover": {
        backgroundColor: "transparent!important",
        border: "none!important",
        color:'#212931!important',
      },
      "&:focus": {
        backgroundColor: "transparent!important",
        outline: "0px",
        color:'#212931!important'
      },
    },
  }));
  const classes = useStyles();
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));


  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 3) {
      setOpenLogoutModal(true);
    }
  };
  const onLogout = () => {
    dispatch(CLEAR_USER());
    localStorage.clear();
    navigate(`/${retailerConfig.brandsite_name}/login`);
  };
  return (
    <Box sx={{ width: "100%", typography: "body1", margin:"" }}>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "transparent",
            margin: "16px 0px 0px 0px",
          }}
        >
          <TabList className={classes.tabList} onChange={handleChange} aria-label="Tab list">
            <Tab className={classes.tab} label="Profile" value="1" />
            <Tab className={classes.tab} label="Shipping" value="2" />
            <Tab className={classes.tab} label="Password" value="3" />
            <Tab className={classes.tab} label="Sign Out" value="4" />
          </TabList>
        </Box>
        {/* tab 1 */}
        <TabPanel className={classes.tabPanel} value="1">
          <ProfileBody
            retailer={retailerConfig}
            isMobile={isMobile}
            showBackArrow={false}
          />
        </TabPanel>

        {/* tab 2 */}
        <TabPanel className={classes.tabPanel} value="2">
          <ShippingAddress
            retailer={retailerConfig}
            isMobile={isMobile}
            showBackArrow={false}
          />
        </TabPanel>

        {/* tab3 */}
        <TabPanel className={classes.tabPanel}  value="3">
          <ChangePassword
            retailer={retailerConfig}
            isMobile={isMobile}
            showBackArrow={false}
            onClose={() => setOpenChangePwdModal(false)}
          />
        </TabPanel>

        {/* Tab 4 */}
        <TabPanel className={classes.tabPanel}  value="4">
          {openLogoutModal && (
            <LogoutModal
              open={openLogoutModal}
              mainColor={colorTheme?.button_background_color}
              mainColorHover={colorTheme?.button_background_color}
              logout={() => onLogout()}
              closeModal={() => setOpenLogoutModal(false)}
            />
          )}
        </TabPanel>
      </TabContext>
    </Box>
  );
}
