import React, { useRef, useState } from 'react';
import { ActionIcon, Button, Group, rem, MantineProvider } from '@mantine/core';
import { IconUpload, IconX, IconExclamationMark } from '@tabler/icons-react';
import { Dropzone } from '@mantine/dropzone';
import { notifications, Notifications } from '@mantine/notifications';

function MantineDropZone(props) {
    const [files, setFiles] = useState([]);
    const openRef = useRef(null);

    const handleFileUpload = (files) => {
        setFiles(files);
      props.onFileUpload(files);
    };

    const handleRemoveFile = (indexToRemove, file) => {
        setFiles((currentFiles) => {
            const updatedFiles = currentFiles.filter((_, index) => index !== indexToRemove);
            handleFileUpload(updatedFiles);
            return updatedFiles;
        });

        notifications.show({
            message: `File ${file.name} removed`,
            position: 'bottom-left',
            autoClose: 3000,
            icon: <IconExclamationMark size={16} />,
            styles: (theme) => ({
                root: {
                    backgroundColor: '#0071CE',
                    color: '#fff',
                },
                icon: {
                    color: '#0071CE',
                    backgroundColor: '#fff',
                    borderRadius: '50%',
                    border: `2px solid #0071CE`,
                },
                closeButton: {
                    backgroundColor: '#0071CE',
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: '#0071CE',
                    },
                },
            }),
        })
    };

    const handleDrop = (droppedFiles) => {
        setFiles((currentFiles) => {
            const totalFiles = currentFiles.length + droppedFiles.length;
            const combinedFiles = [...currentFiles, ...droppedFiles];
    
            if (totalFiles > 5) {
                notifications.show({
                    message: `You can only upload up to 5 files.`,
                    position: 'bottom-left',
                    autoClose: 3000,
                    icon: <IconExclamationMark size={16} />,
                    styles: (theme) => ({
                        root: {
                            backgroundColor: '#f44336',
                            color: '#fff',
                        },
                        icon: {
                            color: '#f44336',
                            backgroundColor: '#fff',
                            borderRadius: '50%',
                            border: `2px solid #f44336`,
                        },
                        closeButton: {
                            backgroundColor: '#f44336',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: '#f44336',
                            },
                        },
                    }),
                });
                return currentFiles; // Do not add any new files if limit is exceeded
            }
    
            handleFileUpload(combinedFiles);

            droppedFiles.forEach((file) => {
                notifications.show({
                    message: `File ${file.name} added`,
                    position: 'bottom-left',
                    autoClose: 3000,
                    icon: <IconUpload size={16} />,
                    styles: (theme) => ({
                        root: {
                            backgroundColor: '#4caf50',
                            color: '#fff',
                        },
                        icon: {
                            color: '#4caf50',
                            backgroundColor: '#fff',
                            borderRadius: '50%',
                            border: `2px solid #4caf50`,
                        },
                        closeButton: {
                            backgroundColor: '#4caf50',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: '#4caf50',
                            },
                        },
                    }),
                });
            });
    
            return combinedFiles;
        });
    };
    
    
    const previews = files.map((file, index) => {
        const imageUrl = URL.createObjectURL(file);

        return (
            <div key={index} style={{ textAlign: 'center' }}>              
                <Group
                    position="apart"
                    style={{
                        border: '1px solid #ccc',
                        borderRadius: '2rem',
                        padding: '0px 4px 0px 6px',
                        marginTop: '4px',
                        display: 'inline-flex',
                        fontSize: '13px'
                    }}
                >
                    <div style={{ padding: '0px 4px' }}>
                        {file.name}
                        <ActionIcon 
                            variant="default"
                            onClick={() => handleRemoveFile(index, file)}
                            size="sm"
                            style={{
                                border: '1px solid #ccc',
                                borderRadius: '2rem',
                                height: '20px',
                                width: '20px',
                                padding: '1px',
                                margin: '4px 0 4px 8px',
                                backgroundColor: '#b6b6b8'
                            }}
                        >
                            <IconX style={{ width: '100%', height: '100%'}} stroke={1.5} />
                        </ActionIcon>
                    </div>
                </Group>
            </div>
        );
    });

    return (
        <MantineProvider>
            <Dropzone
                onDrop={handleDrop}
                onReject={(files) => console.log('rejected files', files)}
                maxSize={25 * 1024 * 1024}
                accept={[
                    'image/png',
                    'image/jpeg',
                    'image/jpg',
                    'application/pdf',
                ]}
            >
                <Group justify="center" gap="xl" mih={220} style={{ pointerEvents: 'all' }}>
                    <Dropzone.Accept>
                        <IconUpload
                            style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }}
                            stroke={1.5}
                        />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                        <IconX
                            style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)' }}
                            stroke={1.5}
                        />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                    <img src='./images/file-arrow-up.png' alt="Upload Icon" style={{ margin: '5px' }} />

                    </Dropzone.Idle>

                    <div
                    style={{
                        fontFamily: 'Oswald',
                        fontSize: '36px',
                        fontWeight: '500',
                        lineHeight: '40px',
                        letterSpacing: '0em',
                        textAlign: 'center',
                        margin: '20px',
                    }}
                    >
                    Drag and drop receipt
                    </div>

                    <Group justify="center" mt="md">
                        <Button 
                            onClick={() => openRef.current?.()} 
                            style={{
                                width: '120px',
                                fontSize: '14px',
                                textAlign: 'center',
                                backgroundColor: '#0071CE',
                                pointerEvents: 'all'
                            }}
                        >
                            Select files
                        </Button>
                    </Group>
                    
                    <img src='./images/fileRequirements.png' alt="accepted file types" style={{ margin: '10px' }} />
                </Group>
            </Dropzone>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                }}
            >
                {previews}
            </div>
            <Notifications/>
        </MantineProvider>

    );
}

export default MantineDropZone;
