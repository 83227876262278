import React, { useRef, useState } from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Webcam from "react-webcam";
import { Button, Grid } from "@material-ui/core";
import CapturedImages from "./CapturedImages";
import { receiptUpload } from "../service/receiptUpload_service";
import { ReactComponent as Frame1Img } from "../assets/images/svg/frame1.svg";
import { ReactComponent as Frame2Img } from "../assets/images/svg/frame2.svg";
import { ReactComponent as Frame3Img } from "../assets/images/svg/frame3.svg";
import { ReactComponent as DeleteCircleIcon } from "../assets/images/svg/deleteCircleIcon.svg";
import { ReactComponent as PlusCircleIcon } from "../assets/images/svg/plusCircleIcon.svg";
import { ReactComponent as SubmitCircleIcon } from "../assets/images/svg/submitCircleIcon.svg";
import { ReactComponent as CameraCircleIcon } from "../assets/images/svg/cameraCircleIcon.svg";
import { default as closeIconDesktop } from "../assets/images/svg/closeIconDesktop.svg";

function DesktopView({
  successMessage,
  open,
  blockStyles,
  handleClose,
  retailerConfig,
}) {
  // setting height and width as per screen size
  const height = window.screen.height / 2 - 20;
  const width = window.screen.width / 2 - 100;

  const useStyles = makeStyles((theme) => ({
    paper: {
      fontFamily: "Switzer, sans-serif",
      backgroundColor: "#FFF",
      padding: "2%",
      height: "fit-content",
      width: "100%",

      '& svg': {
        fill: blockStyles ? blockStyles?.button_background_color : "#0073C5",
      }
    },
    closeButton: {
      padding: "50x",
      float: "right",
      position: "relative",
      alignSelf: "flex-start",
      maxHeight: "48px",
      maxWidth: "115px",
    },
    title: {
      fontFamily: "Switzer, sans-serif",
      color: blockStyles ? blockStyles?.text_color : "#0073C5",
      fontSize: "3.2vw",
      fontWeight: 800,
      lineHeight: "90%",
      letterSpacing: "-.61px",
    },
    heading: {
      color: blockStyles ? blockStyles?.text_color : "#25272C",
      fontSize: "1.4vw",
      fontWeight: 800,
      lineHeight: "90%",
      letterSpacing: "-.27px",
    },
    list: {
      color: blockStyles ? blockStyles?.secondary_color : "#6B7280",
      paddingLeft: "20px",
      fontSize: "1vw",
      fontWeight: 400,
      lineHeight: "150%",
    },
    images: {
      padding: "16px",
      borderRadius: "16px",
    },
    imageContainer: {
      minHeight: "728px",
    },
    infoText: {
      fontSize: "12px",
      color: blockStyles ? blockStyles?.button_text_color : "#FFF",
      fontWeight: "400",
      position: "absolute",
      textAlign: "center",
      top: "5%",
      width: `${width}px`,
      padding: "4px 12px",
      borderRadius: "240px",
      background: blockStyles
        ? blockStyles?.button_background_color
        : "rgba(0, 0, 0, 0.75)",
      backdropFilter: "blur(6.400000095367432px)",
    },
    captureButton: {
      display: "flex",
      justifyContent: "center",
      padding: "5% 15%",
      color: blockStyles ? blockStyles?.button_text_color : "#FFF",
      fontSize: "1.1vw",
      fontWeight: 700,
      lineHeight: "90%",
      letterSpacing: "-.27px",
      backgroundColor: blockStyles
        ? blockStyles?.button_background_color
        : "#0073C5",
      borderRadius: "240px",
      width: "100%",
      boxShadow:
        "0px 38px 11px 0px rgba(29, 60, 109, 0.00), 0px 24px 10px 0px rgba(29, 60, 109, 0.01), 0px 14px 8px 0px rgba(29, 60, 109, 0.05), 0px 6px 6px 0px rgba(29, 60, 109, 0.09), 0px 2px 3px 0px rgba(29, 60, 109, 0.10), 0px 0px 0px 0px rgba(29, 60, 109, 0.10)",
      "&:focus": {
        backgroundColor: blockStyles
          ? blockStyles?.button_background_color
          : "#0073C5",
        outline: "none",
      },
      "&:hover": {
        backgroundColor: blockStyles
          ? blockStyles?.button_background_color
          : "#0073C5",
      },
    },
    reviewButton: {
      display: "flex",
      justifyContent: "center",
      padding: "5% 15%",
      color: blockStyles ? blockStyles?.button_background_color : "#0073C5",
      fontSize: "1.1vw",
      fontWeight: 700,
      lineHeight: "90%",
      letterSpacing: "-.27px",
      backgroundColor: "#FFF",
      borderRadius: "240px",
      border: `1px solid ${
        blockStyles ? blockStyles?.button_background_color : "#0073C5"
      }`,
      width: "100%",
      "&:hover": {
        backgroundColor: "#FFF",
      },
    },
    completedContainer: {
      textAlign: "center",
      backgroundColor: "#fff",
      height: "100%",
      width: "100%",
    },
    titleText: {
      paddingTop: "25%",
      fontSize: "28px",
      fontWeight: 900,
      color: blockStyles ? blockStyles?.text_color : "#25272C",
    },
    submissionInfoText: {
      marginTop: "16px",
      fontSize: "16px",
      fontWeight: 400,
      color: blockStyles ? blockStyles?.text_color : "#25272C",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "16px",
    },
    doneButton: {
      backgroundColor: blockStyles
        ? blockStyles?.button_background_color
        : "#0073C5",
      borderRadius: "240px",
      display: "flex",
      justifyContent: "center",
      color: blockStyles ? blockStyles?.button_text_color : "#FFF",
      fontSize: "16px",
      fontWeight: 700,
      border: `1px solid ${
        blockStyles ? blockStyles?.button_background_color : "#0073C5"
      }`,
      width: "35%",
      padding: "16px 32px",
      "&:focus": {
        backgroundColor: blockStyles
          ? blockStyles?.button_background_color
          : "#0073C5",
        outline: "none",
      },
      "&:hover": {
        backgroundColor: blockStyles
          ? blockStyles?.button_background_color
          : "#0073C5",
      },
    },
    reOpenButton: {
      backgroundColor: "#FFF",
      borderRadius: "240px",
      display: "flex",
      justifyContent: "center",
      color: blockStyles ? blockStyles?.button_text_color : "#0073C5",
      fontSize: "16px",
      fontWeight: 700,
      border: `1px solid ${
        blockStyles ? blockStyles?.button_background_color : "#0073C5"
      }`,
      width: "35%",
      padding: "16px 32px",
      "&:focus": {
        backgroundColor: "#FFF",
        outline: "none",
      },
      "&:hover": {
        backgroundColor: "#FFF",
      },
    },
    buttonText: {
      fontSize: "18px",
      fontWeight: 400,
      color: "#6B7280",
      lineHeight: "150%",
    },
    iconContainer: {
      display: "flex",
      flexDirection: "column",

      "& .submitBtn svg": {
        fill: blockStyles
        ? blockStyles?.button_background_color : '#0073C5'
      }
    },
  }));
  const classes = useStyles();

  const videoConstraints = {
    width: width,
    height: height,
    facingMode: "user",
    // below to used for back camera if req
    // facingMode: { exact: "environment" }
  };
  const videoElement = useRef(null);

  const [capturedImages, setCapturedImages] = useState([]);
  const [currentImage, setCurrentImage] = useState("");
  const [currentIndex, setCurrentIndex] = useState();
  const [retake, setRetake] = useState(false);
  const [captureComplete, setCaptureComplete] = useState(false);

  const capture = React.useCallback(() => {
    const imageSrc = videoElement.current.getScreenshot();
    return imageSrc;
  }, [videoElement]);

  const handleDelete = () => {
    // handle delete an image
    const images = capturedImages.splice(currentIndex, 1);
    setCurrentImage(images);
    if (capturedImages.length == 0) {
      handleCloseModal();
    }
  };
  const handleAdd = () => {
    // handling addition of more images
    setCurrentImage("");
  };
  const handleRetake = () => {
    //  handle retake images
    const imageSrc = capture();
    capturedImages[currentIndex] = imageSrc;
    setCapturedImages([...capturedImages]);
    setRetake(false);
  };
  const handleSubmit = async () => {
    const retailer = retailerConfig.dpn_instance.dpn_instance;
    // handling submit photos
    const response = await receiptUpload(capturedImages, retailer);
    setCaptureComplete(true);
  };
  const handleCurrentImage = (item, i) => {
    // handling current clicked image
    setRetake(false);
    setCurrentImage(item);
    setCurrentIndex(i);
  };
  const handleCapture = () => {
    // handling capturing photo

    if (capturedImages.length < 10) {
      const imageSrc = capture();
      capturedImages.push(imageSrc);
      setCapturedImages([...capturedImages]);
    }
  };
  const handleCloseModal = () => {
    // handling close modal
    setCapturedImages([]);
    setCurrentImage("");
    setCurrentIndex(null);
    setCaptureComplete(false);
    handleClose();
  };

  const modalBody = (
    <Grid className={classes.paper}>
      <Button className={classes.closeButton} onClick={handleCloseModal}>
        <img src={closeIconDesktop} alt="close icon"></img>
      </Button>
      <Grid
        container
        style={{
          padding: "30px 24px",
          justifyContent: "center",
          maxHeight: { height },
        }}
      >
        <Grid item xs={6} style={{ paddingRight: "2%" }}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <h2 className={classes.title}>How to Upload Your Receipt</h2>
              </Grid>
              <Grid className={classes.recept} item xs={12} style={{ marginTop: "4%" }}>
                {/* step 0 */}
                <div style={{ display: "flex" }}>
                  <div style={{ position: "relative" }}>
                  <Frame1Img/>
                  </div>
                  <div style={{ paddingLeft: "5px" }}>
                    <h4 className={classes.heading}>Select Offers and Shop</h4>
                    <ul className={classes.list}>
                      <li>
                        Enroll in the offer(s) you like and buy the required
                        product(s)
                      </li>
                      <li>Save your receipts!</li>
                    </ul>
                  </div>
                </div>

                {/* step 1 */}
                <div style={{ display: "flex" }}>
                  <div style={{ position: "relative" }}>
                    <Frame1Img/>
                  </div>
                  <div style={{ paddingLeft: "5px" }}>
                    <h4 className={classes.heading}>Prepare Your Receipt</h4>
                    <ul className={classes.list}>
                      <li>
                        Ensure it includes the required items and the purchase
                        date.
                      </li>
                      <li>
                        Make sure all details are legible. For long receipts
                        take multiple photos.
                      </li>
                    </ul>
                  </div>
                </div>
                {/* step 2 */}
                <div style={{ display: "flex" }}>
                  <div style={{ position: "relative" }}>
                  <Frame2Img/>
                  </div>
                  <div style={{ paddingLeft: "5px" }}>
                    <h4 className={classes.heading}>Take a Photo</h4>
                    <ul className={classes.list}>
                      <li>
                        Align your receipt in front of the camera, ensuring it
                        fits in the frame.
                      </li>
                      <li>
                        Click the "Capture" button to take the photo. For long
                        receipts, take mutliple photos.
                      </li>
                      <li>
                        Review the photo for clarity and retake if necessary.
                      </li>
                    </ul>
                  </div>
                </div>
                {/* step 3 */}
                <div style={{ display: "flex" }}>
                  <div style={{ position: "relative" }}>
                  <Frame3Img/>
                  </div>
                  <div style={{ paddingLeft: "5px" }}>
                    <h4 className={classes.heading}>Submit Your Photo</h4>
                    <ul className={classes.list}>
                      <li>
                        Click Submit to upload your receipt photo. Points may
                        take up to two days to appear.
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <hr style={{ margin: "2% 0px" }}></hr>
          <Grid item xs={12} className={classes.imageContainer}>
            {/* displaying captured images */}
            <Grid container style={{ justifyContent: "center" }}>
              <CapturedImages
                currentIndex={currentIndex}
                capturedImages={capturedImages}
                handleCurrentImage={handleCurrentImage}
                desktop={true}
              ></CapturedImages>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: "2%" }}>
          <Grid item xs={12} style={{ position: "relative" }}>
            {/* showing camera */}
            {!currentImage && !retake && (
              <>
                <Webcam
                  style={{ borderRadius: "20px", width: "fit-content" }}
                  audio={false}
                  ref={videoElement}
                  videoConstraints={videoConstraints}
                  screenshotFormat="image/png"
                ></Webcam>
                <p className={classes.infoText}>
                  For long receipts, take multiple photos.
                </p>
              </>
            )}

            {/* displaying current image */}
            {currentImage && !retake && (
              <img
                style={{ borderRadius: "20px" }}
                width={width}
                height={height}
                src={currentImage}
              ></img>
            )}

            {/* displayinc camera for retake */}
            {retake && (
              <Webcam
                style={{ borderRadius: "20px" }}
                audio={false}
                ref={videoElement}
                videoConstraints={videoConstraints}
                screenshotFormat="image/png"
              />
            )}
          </Grid>
          {currentImage !== "" && !retake && (
            <Grid
              container
              style={{
                textAlign: "center",
                marginTop: "2%",
                maxWidth: `${width}px`,
              }}
            >
              {/* delete button */}
              <Grid className={classes.iconContainer} item xs={3}>
                <Button onClick={handleDelete}>
                  <DeleteCircleIcon/>
                </Button>
                <span className={classes.buttonText}>Delete</span>
              </Grid>
              <Grid
                className={classes.iconContainer}
                item
                xs={3}
                onClick={() => setRetake(true)}
              >
                {/* retake */}
                <Button>
                <CameraCircleIcon/>
                </Button>
                <span className={classes.buttonText}>Retake</span>
              </Grid>
              <Grid className={classes.iconContainer} item xs={3}>
                {/* add */}
                <Button onClick={handleAdd}>
                  <PlusCircleIcon/>
                </Button>
                <span className={classes.buttonText}>New Photo</span>
              </Grid>
              <Grid className={classes.iconContainer} item xs={3}>
                {/* submit */}
                <Button className="submitBtn" onClick={handleSubmit}>
                  <SubmitCircleIcon fill='#fff' stroke='#fff' />
                </Button>
                <span className={classes.buttonText}>Submit Photos</span>
              </Grid>
            </Grid>
          )}
          {currentImage === "" && !retake && (
            <Grid
              container
              style={{
                textAlign: "center",
                marginTop: "2%",
                justifyContent: "space-between",
                maxWidth: `${width}px`,
              }}
            >
              {/* delete button */}
              <Grid
                item
                xs={6}
                padding={2}
                style={{ padding: "0px 10px 0px 0px" }}
              >
                <Button
                  className={classes.captureButton}
                  onClick={handleCapture}
                >
                  Capture Photo
                </Button>
              </Grid>
              {capturedImages.length >= 1 && (
                <Grid
                  style={{ display: "flex", padding: "0px 0px 0px 10px" }}
                  item
                  xs={6}
                  padding={2}
                  onClick={() =>
                    setCurrentImage(capturedImages[capturedImages.length - 1])
                  }
                >
                  {/* review */}
                  <Button
                    className={classes.reviewButton}
                    onClick={() =>
                      setCurrentImage(capturedImages[capturedImages.length - 1])
                    }
                  >
                    Review
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
          {/* handling button for retake */}
          {retake && (
            <Grid
              container
              style={{
                maxWidth: "28%",
                textAlign: "center",
                marginTop: "2%",
                justifyContent: "center",
              }}
            >
              {/* delete button */}
              <Grid item xs={12}>
                {/* retake */}
                <Button className={classes.reviewButton} onClick={handleRetake}>
                  Retake Photo
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );

  // modal body after submission success
  const submissionBody = (
    <Grid className={classes.completedContainer}>
      <Grid item xs={12} className={classes.titleText}>
        Thanks!
      </Grid>
      <Grid item xs={12}>
        <p className={classes.submissionInfoText}>{successMessage}</p>
      </Grid>
      <Grid
        container
        style={{ display: "flex", justifyContent: "space-evenly" }}
      >
        <Button
          className={classes.doneButton}
          onClick={() => handleCloseModal()}
        >
          Done
        </Button>
        <Button
          className={classes.reOpenButton}
          onClick={() => handleCloseModal()}
        >
          Upload Another
        </Button>
      </Grid>
    </Grid>
  );
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {captureComplete ? submissionBody : modalBody}
      </Modal>
    </div>
  );
}

export default DesktopView;
