import axios from "axios";
import { getBaseUrl } from "../config/retailerConfig";

export const getLoyalityPrograms = async (token, retailer, groupType, page) => {
  const headers = {
    "Content-Type": "application/json",
    "x-retailer": retailer,
    "x-auth-token": token,
  };
  try {
    const response = await axios.get(
      `${getBaseUrl(retailer)}/loyalty?groups=${groupType}&page=${page}`,
      { headers }
    );
    response.data.token = token;
    return response.data;
  } catch (error) {
    return { error };
  }
};

export const getProgramDetails = async (retailer, id, token) => {
  const headers = {
    "Content-Type": "application/json",
    "x-retailer": retailer,
    "x-auth-token": token ? token : localStorage.getItem("token"),
  };
  try {
    const response = await axios.get(
      `${getBaseUrl(retailer)}/loyalty/${id}`,
      { headers }
    );
    return response.data;
  } catch (error) {
    return { error };
  }
};

export const getAvailableProgramDetails = async (
  token,
  retailer,
  groupType
) => {
  const headers = {
    "Content-Type": "application/json",
    "x-retailer": retailer,
    "x-auth-token": token,
  };
  try {
    const response = await axios.get(
      `${getBaseUrl(retailer)}/loyalty?groups=${groupType}&size=3`,
      { headers }
    );
    response.data.token = token;
    return response.data;
  } catch (error) {
    return { error };
  }
};

export const enrollProgram = async (retailer, id, token = null) => {
  try {
    const authToken = token || localStorage.getItem("token");
    
    const response = await axios.post(
      `${getBaseUrl(retailer)}/loyalty/clip`,
      {
        id: id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-retailer": retailer,
          "x-auth-token": authToken,
        },
      }
    );
    return response.status === 200
      ? response.data
      : { error: "Unable to enroll the program" };
  } catch (error) {
    return { error: "Unable to enroll the program" };
  }
};

