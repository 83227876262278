import React, { useState } from "react";
import { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  useMediaQuery,
  createTheme,
  Box,
  Container,
  Button
} from "@material-ui/core";
import FormWrapper from "../blocks/Account/FormWrapper";
import EnrollmentStepsComponent from "../blocks/Account/EnrollmentSteps";
import { getFullPath } from "../config/retailerConfig";
import { SiteConfigContext } from "../SiteConfigContext";

function SignupWrapper(props) {
  const signupConfig = props.page.components.find(
    (component) => component.block_type === "SignupBlock"
  );
  const ageGateConfig = props.page.components.find(
    (component) => component.block_type === "AgeGateBlock"
  );
  const stateGateConfig = props.page.components.find(
    (component) => component.block_type === "StateGateBlock"
  );
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up(1200));
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const siteConfig = useContext(SiteConfigContext);
  const colorTheme = siteConfig[0].brand_design_theme;


  const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexDirection: isLargeScreen ? 'row' : 'column',
      gap: theme.spacing(4),
      maxWidth: 1300,
      margin: '0 auto',
      padding: isMobile ? theme.spacing(2) : theme.spacing(4),
    },
    howItWorksContainer: {
      flex: isLargeScreen ? '1 1 50%' : '1 1 auto',
    },
    signupContainer: {
      flex: isLargeScreen ? '1 1 50%' : '1 1 auto',
    },
    signInbtn: {
      display: "flex",
      alignSelf: "flex-start",
      width: 'fit-content',
      height: '100%',
      padding: '16px 48px',
      background: signupConfig.primary_color || colorTheme?.button_background_color,
      boxShadow: "0px 3px 5px rgba(107, 114, 128, 0.15)",
      borderRadius: 240,
      gap: 16,
      color: signupConfig.button_text_color || colorTheme?.primary_button_text_color,
      fontSize: "18px",
      fontFamily: 'Switzer',
      fontWeight: '700',
      lineHeight: '27px',
      textTransform: 'none',
      marginTop: '8px',
      marginLeft: '8px',
      '&:hover': {
        background: signupConfig.primary_color || colorTheme?.button_background_color,
        cursor: 'default',
      },
    },
    signInbtnMobile: {
      display: "flex",
      alignSelf: "flex-start",
      color: signupConfig.button_text_color || colorTheme?.primary_button_text_color,
      outline: "none",
      padding: "1.2rem 1.6rem",
      fontSize: "18px",
      width: 'fit-content',
      borderRadius: 240,
      boxShadow: "0px 3px 5px rgba(107, 114, 128, 0.15)",
      fontWeight: '700',
      lineHeight: '27px',
      background: signupConfig.primary_color || colorTheme?.button_background_color,
      fontFamily: 'Switzer',
      textTransform: 'none',
      marginTop: "-28px",
      marginLeft: "0px",
      "&:hover": {
        boxShadow: "none",
        background: signupConfig.primary_color || colorTheme?.button_background_color,
      },
      "&:focus": {
        boxShadow: "none",
        background: signupConfig.primary_color || colorTheme?.button_background_color,
        outline: "none",
      },
      "&:focus-visible": {
        outline: "none",
      },
    },
    paper: {
      maxWidth: 630,
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(5),
      boxShadow: "none",
    },
    paperMobile: {
      maxWidth: 560,
      margin: `${theme.spacing(1)}px auto`,
      padding: "28px 2px 10px 2px",
      boxShadow: "none",
      margin: 0,
      padding: 0
    },
    loginLogo: {
      width: "200px",
      height: "auto",
      margin: isMobile ? "1rem" : "1rem 4rem",
      objectFit: "contain",
      marginBottom: 0
    },
    signIntxt: {
      fontSize: isMobile ? "30px" : "60px",
      fontFamily: "Switzer",
      fontWeight: 700,
    },
    createAccount: {
      padding: "10px",
      fontSize: "16px",
      fontFamily: "Switzer",
    },
    createTxt: {
      color: `${signupConfig?.primary_color} !important`,
      cursor: "pointer",
      fontFamily: "Switzer",
      fontWeight: 'bold',
    },
    container2: {
      margin: 0,
      padding: 0
    }
  }));

  const classes = useStyles();
  const navigate = useNavigate();
  const formFieldsData = signupConfig.retailerFields?.formFields;
  const formFields = formFieldsData.map(item => {
    if (!item.hasOwnProperty("stepId")) {
      return { ...item, stepId: 1 };
    }
    return item;
  });
  const uniqueStepIds = [...new Set(formFields.map(item => item.stepId))];

  const enrollmentSteps = signupConfig.enrollment_steps;
  if (!signupConfig) {
    return <>Loading...</>;
  }
  const signupConfirmationText = signupConfig.signup_confirmation_text
    ? signupConfig.signup_confirmation_text.replaceAll(
      "<CPG name>",
      props.page.dpn_instance.dpn_instance
    )
    : "";


  const handleFieldChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className={classes.container2}>
      <Grid item xs={12} zeroMinWidth style={{ textAlign: signupConfig?.logo_Alignment }}>
        <a onClick={() => navigate(getFullPath(""))}>
          <img
            tabIndex={0}
            className={classes.loginLogo}
            alt="logo"
            src={signupConfig.logo}
          />
        </a>
      </Grid>

      <Container className={classes.container}>
        {props.page.site_type === 'LoyaltySync' && (
          <Box className={classes.howItWorksContainer}>
            <EnrollmentStepsComponent enrollmentSteps={enrollmentSteps} retailerDpnInstance={props.page.retailer_dpn_instance} signupConfig={signupConfig} />
          </Box>
        )}

        <Box className={classes.signupContainer}>
          <Paper className={isMobile ? classes.paperMobile : classes.paper}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item xs={12} zeroMinWidth style={{ textAlign: "center" }}>
                <Typography
                  tabIndex={0}
                  className={classes.signIntxt}
                  noWrap
                  style={{ textAlign: "left", marginBottom: '40px' }}
                  data-testid="signIn"
                >
                  {currentStep === 1 ? signupConfig.page_title : "Finish your Profile"}
                </Typography>
                {uniqueStepIds.length > 1 ? <Typography
                  tabIndex={0}
                  style={{ textAlign: "left" }}
                  data-testid="donthaveAccount"
                >
                  Step {currentStep} of {uniqueStepIds.length}
                </Typography>: ""}


                <FormWrapper
                  formType="register"
                  ageGateConfig={ageGateConfig}
                  stateGateConfig={stateGateConfig}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  stepId={currentStep}
                  signUpSteps={uniqueStepIds}
                  config={signupConfig}
                  brandsiteName={props.page.brandsite_name}
                  confirmTxt={signupConfirmationText}
                  fields={formFields.filter((field) => field.stepId === currentStep)}
                  retailerName={props.page.dpn_instance.dpn_instance}
                  loyaltyConnectRetailerName={props.page.retailer_dpn_instance.dpn_instance}
                  loyaltyConnectRetailerAccountName={props.page.retailer_dpn_instance.retailer_account_name}
                  loyaltyConnectRetailerIdType={props.page.retailer_dpn_instance.retailer_id_type}
                  enrollmentType={props.page.site_type}
                  loyaltyProgramId={props.page.retailer_dpn_instance.loyalty_program_id}
                  onFieldChange={handleFieldChange}
                />

                <Typography
                  tabIndex={0}
                  noWrap
                  data-testid="donthaveAccount"
                  style={{
                    textAlign: 'left',
                    marginTop: isMobile ? '-8px' : '32px',
                    marginBottom: isMobile ? '8px' : '',
                    fontFamily: 'Switzer',
                    lineHeight: '150%',
                    fontSize: isMobile ? '16px' : '18px'
                  }}
                >
                  Have an account?
                  <span
                    data-testid="createAccount"
                    onClick={() => navigate(getFullPath("login"))}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        navigate(getFullPath("login"));
                      }
                    }}
                    style={{
                      color: signupConfig?.primary_color,
                      cursor: 'pointer',
                      textDecoration: 'none',
                      fontWeight: 700,
                    }}
                    tabIndex={0}
                  >
                    {" "}
                    Log in
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
    </div>
  );
}

export default SignupWrapper;